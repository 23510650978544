import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class kbcardAdaptiveCardModel{
  public text: string | DecoratedText;
  public kbarticleNumber: string | DecoratedText;
  public warningMsg: string | DecoratedText;
  public choices: Array<object> = [];
  public selectedOption: number; // will store the idx of the selected choice
  public shouldSendAsValue: boolean = false;
  public isOptionsEnabled: boolean = false;

  private parsePromptAndInit(rawdata: any) {
    console.log(
      "kbcardAdaptiveCardModel ~ parsePromptAndInit ~ rawdata",
      rawdata
    );
    this.text = new DecoratedText(rawdata.message);
    this.kbarticleNumber = new DecoratedText(rawdata.message);
    this.warningMsg = new DecoratedText(rawdata.message);
    this.choices = rawdata.rawData.split("|");
  }

  private parseCardAndInit(rawCard: any) {
    console.log(
      "kbcardAdaptiveCardModel ~ parsePromptAndInit ~ rawCard",
      rawCard
    );
    // this.text= new DecoratedText("sample");
    // this.choices=["s","2","3333"]
    // console.log("donnnn");

    if (rawCard.content.body) {
      this.warningMsg = new DecoratedText(rawCard.content.body[0].text);
      
      this.text = new DecoratedText(rawCard.content.body[1].columns[0].items[0].text);
      console.log("kbcardAdaptiveCardModel ~ parseCardAndInit ~ text", this.text)
      this.kbarticleNumber = rawCard.content.body[1].columns[0].items[0].text.split('|')[1];     
      console.log("kbcardAdaptiveCardModel ~ parseCardAndInit ~ kbarticleNumber", this.kbarticleNumber)
      
    }
    
    // if(rawCard.content.actions){
    //     if(rawCard.content.actions[0].type == "Action.Submit"){
    //         this.shouldSendAsValue = true;
    //     }

    //     this.choices = rawCard.content.actions.map( actionObj => {
    //         return actionObj.title;
    //     } );
    // }

    // this.shouldSendAsValue = false;
    // console.log("CustomOnBehalfSRAdaptiveCardModel ~ parseCardAndInit ~ rawCard.content.body[1].actions", rawCard.content.body[0].items)
    
    if (rawCard.content.body[1].columns[1].items[0].actions) {
      console.log(
        "kbcardAdaptiveCardModel ~ parseCardAndInit ~ rawCard.content.body[1].actions",
        rawCard.content.body[1].columns[1].items[0].actions
      );
      let data = [];
      rawCard.content.body[1].columns[1].items[0].actions.forEach((at) => {
        console.log(
          "kbcardAdaptiveCardModel ~ parseCardAndInit ~ at",
          at
        );
        data.push(at);
      });
      this.choices = data;
      console.log(
        "kbcardAdaptiveCardModel ~ parseCardAndInit ~ choices",
        this.choices
      );

      if (rawCard.content.body[1].columns[1].items[0].actions[0].type == "Action.Submit") {
        console.log("inennn");
        this.shouldSendAsValue = false;
      }
    }
  }

  markOption(idx: number) {
    this.selectedOption = idx;
  }

  public reactToNewMessage(newMsg: ChatMessageModel) {
    this.isOptionsEnabled = false;
  }

  constructor(message: any) {
    if (message.attachments) {
      // Action card
      this.parseCardAndInit(message.attachments[0]);
    } else {
      // Not a card -> its a choice prompt
      this.parsePromptAndInit(message.channelData);
    }
  }
}
