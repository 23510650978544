import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class FeedbackSurveyCardModel {
  public text: string | DecoratedText;
  public divider: string | DecoratedText;
  public choices: Array<object> = [];
  public selectedOption: number; // will store the idx of the selected choice
  public shouldSendAsValue: boolean = false;
  public isOptionsEnabled: boolean = false;

  markOption(idx: number) {
    this.selectedOption = idx;
  }

  public reactToNewMessage(newMsg: ChatMessageModel) {
    this.isOptionsEnabled = false;
  }
  constructor(
    public rawData: any,
    public timestamp: any
    ) {
    console.log("rawdata for survey", rawData);
    let rawCard = rawData.attachments[0].content.body;
    console.log("rawCard for survey", rawCard);

    let data ={
      heading : rawCard[0].text,
      heading1 : rawCard[2].text,
      buttonText:rawCard[3].columns[0].items[0].actions[0].title
    }
    
    rawCard[1]["columns"].map(actionObj=>{
        console.log("rawCard1Result", actionObj);
      if(actionObj.type == "Column") {
        if(actionObj["items"] != undefined && actionObj["items"].length>0) {
          actionObj["items"].map((actionObj1: any) => {
            if (actionObj1.type == "Image") {
              let imgUrl = actionObj1.url;
              console.log("imgUrl",imgUrl);
              let imgValue = actionObj1.selectAction.data.value;
              console.log("imgValue",imgValue);
              let choiceObjects = {
                image: imgUrl,
                value: imgValue
              }
              this.choices.push(choiceObjects);
            }
          })
        }
      }
    })
    console.log("FeedbackSurveyChoicesData",this.choices);
    this.rawData = data;
  }
}
