import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class PlanetCustomToggleAdaptiveCardModel {
    
    public locationPlaceholder: string;
    public firstButton: Array<object> = [];
    public secondButton: Array<object> = [];
    public submitButton: Array<object> = [];
    public choices: Array<object> = [];
    public selectedOption: number; // will store the idx of the selected choice
    public shouldSendAsValue: boolean = false;
    public isOptionsEnabled: boolean = false;


    private parseCardAndInit(rawCard: any){
        let actionData = rawCard.content.body[0].actions;
        console.log("actionData",actionData);
        actionData.map((actionDataObj)=>{
            if(actionDataObj.type == "Action.Submit") {
                this.firstButton.push(actionDataObj.title);
            } 
            if(actionDataObj.type == "Action.ShowCard") {
                this.secondButton.push(actionDataObj.title);
                this.submitButton.push(actionDataObj.card.actions[0].title);
                this.locationPlaceholder = actionDataObj.card.body[0].placeholder;
                actionDataObj.card.body[0].choices.forEach((location:any)=>{
                    this.choices.push(location);
                });
            }
        })
    }

    markOption(idx: number){
        this.selectedOption = idx;
    }

    public reactToNewMessage(newMsg: ChatMessageModel){
        this.isOptionsEnabled = false;
    }

    constructor(message: any){
        if (message.attachments) {
            // Action card
            this.parseCardAndInit(message.attachments[0]);
        }
    }

}
