import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class selfHeal2WebCardModel {
  public text: string | DecoratedText;
  public choices: Array<string> = [];
  public choicesCondition: Array<string> = [];
  public choicesCondition1: Array<string> = [];
  public choicesCondition2: Array<string> = [];
  public selectedOption: number; // will store the idx of the selected choice
  public shouldSendAsValue: boolean = false;
  public isOptionsEnabled: boolean = true;
  data: any;
  public selfHealData: Array<string> = [];
  public userDevices: Array<string> = [];
  public userDeviceTitle: string | DecoratedText;


  private parsePromptAndInit(rawdata: any) {
    this.text = new DecoratedText(rawdata.message);
    this.choices = rawdata.rawData.split('|');
  }


  private parseCardAndInit(rawCard: any) {
    console.log("selfHeal2WebRawData ~ ", rawCard)


    if (rawCard.content.body) {
      this.text = new DecoratedText(rawCard.content.body[0].items[0].columns[0].items[0].text);
    }

    if (rawCard.content.actions) {
      this.choicesCondition = rawCard.content.actions.map(actionObj => {
        return actionObj.title;
      });




       rawCard.content.actions[0].card.actions.filter(actionObj1 => {
        if (actionObj1.title == 'Yes') {
          this.choicesCondition1.push(actionObj1.title);
        }
      });

      rawCard.content.actions[0].card.actions.filter(actionObj2 => {
        if (actionObj2.title == 'No') {
          this.choicesCondition2.push(actionObj2.title);
        }
      });

      this.userDeviceTitle = new DecoratedText(rawCard.content.actions[0].card.actions[0].card.body[0].text);

      this.userDevices = rawCard.content.actions[0].card.actions[0].card.actions.map(deviceTitle =>{
        return deviceTitle.title;
      })

      rawCard.content.actions[0].card.body[0].items.forEach((selfHealObject: any) => {
        if (selfHealObject.type != 'Container') {
          this.selfHealData.push(selfHealObject);
        }
        else if (selfHealObject.type == 'Container') {
          selfHealObject.items.forEach((selfHealContainer) => {
            this.selfHealData.push(selfHealContainer);
          })
        }
      })

      if (rawCard.content.actions[0].card.actions.type == "Action.Submit") {
        this.shouldSendAsValue = true;
      }

      this.choices = rawCard.content.actions[0].card.actions.map(actionObj => {
        return actionObj.title;
      });
    }
  }


  markOption(idx: number) {
    console.log("ChoiceCardModel ~ markOption ~ idx", idx)
    this.selectedOption = idx;
  }


  public reactToNewMessage(newMsg: ChatMessageModel) {
    this.isOptionsEnabled = false;
  }


  constructor(message: any) {
    if (message.attachments) {
      // Action card
      this.parseCardAndInit(message.attachments[0]);
    } else {
      // Not a card -> its a choice prompt
      this.parsePromptAndInit(message.channelData);
    }
  }

}
