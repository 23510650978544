import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppStateService } from '../services/app-state.service';
import { AuthenticationService } from '../services/authentication.service';
import { I18nService } from '../services/i18n.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  menu: boolean = true;
  userName: string = '';

  // private async retrieveUserDetails(authToken: string){

  //   this.authService.fetchUserDetails(authToken).subscribe(
  //     data => {
  //       console.log('[fetchUserDetails]: Success!!');
  //       console.log('[fetchUserDetails - Success]: ', data);
  //       this._appStateService.setUserDetails(data);
  //     },

  //     err => {
  //       console.log('[fetchUserDetails - Error]: ', err);
  //     });
  // }

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public _appStateService: AppStateService,
    public _i18nService: I18nService,
  ) { }


  ngOnInit() {
    var self = this;
    let sub = this.route
      .queryParams
      .subscribe(params => {
        let data = atob(params['data'] || '');
        if (data !== '') {
          let authObj = JSON.parse(data);
          console.log('This is the authObj : ', authObj);
          self.userName = authObj.bot_user.substring(0, authObj.bot_user.lastIndexOf('@'));
          // await this.retrieveUserDetails(authObj['csdXAuthToken']);
        }
        else{
          this.router.navigate(['/login']);
        }
      });
  }

}
