import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class CustomSingleStatusCardModel {
  selectedOption: number;

  private parseStatusDetails(rawdata: any) {
    let v = rawdata.split(" : ");
    return { title: new DecoratedText(v[0]), value: v[1] };
  }

  private parseRawData(rawdata: any, cardType: string) {
    console.log("CustomSingleStatusCardModel ~ parseRawData ~ rawdata", rawdata)
    let parsedData = {};

    parsedData["title"] = rawdata[0].text; // Title
    parsedData["num"] = rawdata[1].text; // tkt num / RITM num
    let datas = [];
    let CardActions = [];

    rawdata.forEach((el, ind) => {      
      if (el["type"] === "TextBlock") {
        if (ind > 1) {
          datas.push({ value: this.parseStatusDetails(el["text"]) });
        }
      } else {
        el["actions"].forEach((actions) => {
          console.log("single status card actions", actions)
          let act = {};
          if (actions["title"] !== "Back To Menu") {
            
            (act["title"] = actions["title"]), //  title
            (act["title1"] =actions["title"]), //  title

              (act["text"] = actions.card["body"][0]["label"]), //  text,
              (act["placeholder"] = actions.card["body"][0]["placeholder"]), //  placeholder
              (act["requiredMsg"] = actions.card["body"][0]["errorMessage"]), // requiredMsg
              (act["id"] = actions.card["body"][0]["id"]), //  id,
              (act["value"] = actions.card["body"][1]["actions"][0]["data"]), //value
              (act["btnTitle"] =
                actions.card["body"][1]["actions"][0]["title"]);
            CardActions.push(act);
          }
          else{
            console.log("dsafffffffffffffffffff",actions);
               (act["title"] = actions["title"]), //  title
               (act["title1"] = actions["data"].msteams["value"]), //  title1

                 (act["value"] = actions.data), //value
                 CardActions.push(act);
          }
        });
      }
    });

    parsedData["datas"] = datas; // Status details
    parsedData["cardActions"] = CardActions; // Action Set (AddComments/Cancel datas)
    

    console.log("CustomSingleStatusCardModel ~ parseRawData ~ parsedData", parsedData)
    return parsedData;
  }

  formatTime(time: Date) {
    let hours: number = time.getHours();
    let ampm: string = (hours >= 12) ? 'pm' : 'am';
    let minutes: number = time.getMinutes();
    let fMins: string = (minutes < 10) ? `0${minutes}` : minutes.toString();

    // the hour '0' should be '12'
    hours = (hours % 12) ? (hours % 12) : 12;

    return `${hours}:${fMins} ${ampm}`;
}

markOption(idx: number){
  this.selectedOption = idx;
}

  constructor(
    public cardData: any,
    public timestamp: any,
    public cardType: string
  ) {
    this.timestamp=this.formatTime(timestamp)
    let rawData = cardData.body;
    console.log("CustomSingleStatusCardModel ~ rawData", rawData)
    let res = this.parseRawData(rawData, cardType);
    console.log("CustomSingleStatusCardModel ~ res", res)
    this.cardData = res;
  }
}
