import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AppStateService } from '../services/app-state.service';
import swal from 'sweetalert';
import { I18nService } from '../services/i18n.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})
export class Login2Component implements OnInit, OnDestroy {

  public readonly env = environment;
  private countLoginInfoHit: number;
  private readonly maxLoginInfoHitCount = 5;
  public isLoginFormShown = false;
  private username: string;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  userName: any;
  password: any;
  tenantID : any;
  authObject: any;


  private i18nConfig(authObj: any) {
    this._i18nService.clearLanguages();
    this._i18nService.setLanguages([authObj.defaultLang]);
    // this._i18nService.setLanguages(authObj.availableLangs);
    this._i18nService.setDefaultLocale();
  }





 

  



 


  


  constructor(
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _route: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService,
    public _appStateService: AppStateService,
    public _i18nService: I18nService,
    private _http: HttpClient
  ) { }


  ngOnInit() {
    this.spinnerService.show();
 
    
    
      sessionStorage.setItem('refreshCount',"0")
    this._route.queryParams.subscribe(
      params => {
        const authToken = params['authToken'];
        sessionStorage.setItem('authToken',authToken)
        const storageAccessToken = sessionStorage.getItem('authToken');
      //  console.log('[Login2 - ngOnInit] authToken retrieved!');
        // console.log('[Login2 - ngOnInit] authToken: ', authToken);

        if (storageAccessToken == "undefined") {
          console.log("Redirecting to api gateway"+new Date().getTime())
          window.location.assign(environment.apiGatewayBaseUrl+'oauth2/authorization/okta?domain='+environment.domain)
        }
   console.log("Auth token retreived "+new Date().getTime());
        // If authToken present in the URI then fetch the login details.
        this.countLoginInfoHit = 0;
        this._appStateService.setAuthToken(storageAccessToken);
        console.log("Login2Component ~ ngOnInit ~ authToken", storageAccessToken)

        this._appStateService.myAuthToken=storageAccessToken
        this.fetchLoginInfo(storageAccessToken);

      });
    
    
  }

  private fetchLoginInfo(authToken: string) {
    
  
    this.countLoginInfoHit++;

    this.authService.fetchAuthInfo(authToken).subscribe(
      data => {
        console.log('[fetchAuthInfo]: Success!!');
        this._appStateService.shouldShowGreeting = true;
        this.userName = data["responseDetails"][0].userId;
	      this._appStateService.setVoiceSubscriptionDetails(data["responseDetails"][0]);
        const authObj = this.createAuthObj(data, authToken);
        this.i18nConfig(authObj);
        // if(this._appStateService.getIsSNOW()){
        //   this.router.navigate([], {
        //     queryParams: {
        //       data: btoa(unescape(encodeURIComponent(JSON.stringify(authObj))))
        //     },
        //     queryParamsHandling: 'merge',
        //   });
        // }
        // else{
          this.router.navigate(['/admin/chat-box'], { queryParams: { data: btoa(unescape(encodeURIComponent(JSON.stringify(authObj)))) } });
       // }
        
      },

      err => {
        console.log('[fetchAuthInfo - Error]: ', err);
        if (this.countLoginInfoHit <= this.maxLoginInfoHitCount) {
          console.log(`[fetchAuthInfo - Error] Retrying fetching details (Count - ${this.countLoginInfoHit})`);
          setTimeout(() => {
            console.log(`[fetchAuthInfo - Timeout] Initiating hit ...`);
            this.fetchLoginInfo(authToken);
          }, 3500);
        } else {
          this.spinnerService.hide();
          swal({
            text: 'Something went wrong! Please try again.',
            icon: 'error',
            className: 'swal-wide'
          });
        }
      });
  }



  createAuthObj(authResponse: any, csdXAuthToken: string) {
    const data = authResponse["responseDetails"][0];
    const authObj = {
      "bot_user": this.userName,
      "jwtToken": data['jwtToken'],
      "csdXAuthToken": csdXAuthToken,
      "digitalId": data['digitalId'],
      "directLineToken": data['directLineToken'],
      "defaultLang": data['defaultLang'],
      "availableLangs": data['languages']
    };
    console.log('[createAuthObj] authObj: ', JSON.stringify(authObj));
    return authObj;
  }


  ngOnDestroy() {
    this.spinnerService.hide();
  }

}
