import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class CustomToggleAdaptiveCardModel {
    
    public text: string|DecoratedText;
    public choices: Array<object> = [];
    public selectedOption: number; // will store the idx of the selected choice
    public shouldSendAsValue: boolean = false;
    public isOptionsEnabled: boolean = false;
    

    private parsePromptAndInit(rawdata: any){
    console.log("CustomAdaptiveCardModel ~ parsePromptAndInit ~ rawdata", rawdata)
        this.text = new DecoratedText(rawdata.message);
        this.choices = rawdata.rawData.split('|');
    }


    private parseCardAndInit(rawCard: any){
    console.log("CustomAdaptiveCardModel ~ parsePromptAndInit ~ rawCard", rawCard)
// this.text= new DecoratedText("sample");
// this.choices=["s","2","3333"]
// console.log("donnnn");

        if(rawCard.content.body){
            this.text =  new DecoratedText(rawCard.content.body[0].text);
        }

        // if(rawCard.content.actions){
        //     if(rawCard.content.actions[0].type == "Action.Submit"){
        //         this.shouldSendAsValue = true;
        //     }
            
        //     this.choices = rawCard.content.actions.map( actionObj => {
        //         return actionObj.title;
        //     } );
        // }

        // this.shouldSendAsValue = false;
        if(rawCard.content.body[1].actions){
        console.log("CustomAdaptiveCardModel ~ parseCardAndInit ~ rawCard.content.body[1].actions", rawCard.content.body[1].actions)
        let data = []
           rawCard.content.body[1].actions.forEach(at => {
            console.log("CustomAdaptiveCardModel ~ parseCardAndInit ~ at", at)
            data.push(at)                
            });
            this.choices = data;
            console.log("CustomAdaptiveCardModel ~ parseCardAndInit ~ choices", this.choices)

            if( rawCard.content.body[1].actions[1].type == "Action.Submit"){
                console.log("inennn");                
                this.shouldSendAsValue = false;
            }
            
            // this.choices = rawCard.content.body[1].actions.map( actionObj => {
            
            //     return actionObj.title;
            // } );
        }
    }


    markOption(idx: number){
        this.selectedOption = idx;
    }


    public reactToNewMessage(newMsg: ChatMessageModel){
        this.isOptionsEnabled = false;
    }


    constructor(message: any){
        if (message.attachments) {
            // Action card
            this.parseCardAndInit(message.attachments[0]);
        }else{
            // Not a card -> its a choice prompt
            this.parsePromptAndInit(message.channelData);
        }
    }

}
