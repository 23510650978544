import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { AppStateService } from '../services/app-state.service';
import { AuthenticationService } from '../services/authentication.service';

import { Subscription, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs-compat/operator/debounceTime';
import { map, startWith, tap } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs-compat/operator/distinctUntilChanged';
import { I18nService } from '../services/i18n.service';
import { Router } from '@angular/router';
import { DirectLine } from '../services/botframework-directlinejs';
import { SharedService } from '../services/sharedService';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

  public i18nTexts: any;
  public thumbnail: any;
  public imgSrc: any = "assets/Icons/User_Icon_2.svg";
  public agentStatus: string;

  subscribeToWinResize(){
    this._appStateService.resizeWidth$.subscribe(
      data => {
        console.log("** Win resized from Nav: ", data);
      }
    );
  }
  

  close(){
    sessionStorage.removeItem('authToken');
    this.router.navigateByUrl('/logout');     
    // this._sharedService.callTriggerLogout();
    // this.authService.logoutUser().subscribe(
    //   resp => {
    //   console.log("NavbarComponent ~ close ~ resp", resp)
    //   sessionStorage.clear();
    //   this.router.navigate(['/login']);
    //   },
    //   error => {
    //     console.log("error onLogout",error)
    //   });
      
  }

  



 /* close(){
    // console.log("Logout triger:");
    // window.open('', '_self', '');
    window.close();
    //this._appStateService.changeMessage("passing logout event");
   
    // setTimeout(function(){
    //   this.router.navigate(['/login']);
    //   window.close();      
    // }, 1000);
  }
  */
  changeLanguage(){
    this._i18nService.setLocale(1);
  }

  // getThumbnailImage(thumbnailDetails: any) {
  //   this.authService.fetchThumbnailImage(thumbnailDetails).subscribe(
  //     data => {
  //       console.log('[fetchAuthInfo]: Success!!');
  //       let url =  URL.createObjectURL(data);
  //       this.imgSrc = this.sanitize(url)
  //     },

  //     err => {
  //       console.log('[fetchAuthInfo - Error]: ', err);
  //       this.imgSrc = "assets/Icons/User_Icon_2.svg";        
  //     }
  //   );
  // }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  userClicked(value :string){
    this._sharedService.userClickedfromLeftmenu(value);
    this.agentStatus=value;

  }


  constructor(
    private authService: AuthenticationService,
    public _appStateService: AppStateService,
    private _i18nService: I18nService,
    private sanitizer:DomSanitizer,
    public router: Router,
    public _sharedService:SharedService
    
  ) {
    console.log(` ** my name => ${this.constructor.name}`);
    this.i18nTexts = this._i18nService.getLocaleTextsForComponent(this.constructor.name);
  }


  ngOnInit() {
    // if(!this._appStateService.getThumbnailDetails()){
    //   this._appStateService.setThumbnailDetails(localStorage.getItem("thumbnailToken"), localStorage.getItem("thumbnailPath"));
    // }    
    // this.geumbnailImage(this._appStateService.getThumbnailDetails());
    
    this.subscribeToWinResize();
    if(this._appStateService.isAgent){
      setInterval(() => {
        setTimeout(() => {

          
          }, 1000);
         
        }, 2000);
    }
   
  }


  ngOnDestroy() {
    // Un-subscribe ??
  }

//   agentTestStatus: { show: boolean, status: string }[] = [
//     { "show": true, "status": "Available" },
//     { "show": true, "status": "Away" },
//     { "show": true, "status": "Busy" },
//     { "show": true, "status": "offline" }
// ];

// showstatus(){
//   if(this.agentStatus=="AVAILABLE"){
//     return "AVAILABLE"
//   }
//   else if(this.agentStatus=="BUSY"){
//     return "BUSY"
//   }
//   else if(this.agentStatus=="AWAY"){
//     return "AWAY"
//   }
//   else{
//     return "OFFLINE"
//   }
// }

// convid=DirectLine.conversationId;
}
