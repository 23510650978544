import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class NegativeFeedbackSurveyCardModel {
  public text: string | DecoratedText;
  public divider: string | DecoratedText;
  public choices: Array<object> = [];
  public selectedOption: number; // will store the idx of the selected choice
  public shouldSendAsValue: boolean = false;
  public isOptionsEnabled: boolean = false;

  markOption(idx: number) {
    this.selectedOption = idx;
  }

  public reactToNewMessage(newMsg: ChatMessageModel) {
    this.isOptionsEnabled = false;
  }
  constructor(
    public rawData: any,
    public timestamp: any
    ) {
    console.log("rawdata for survey", rawData);
    let rawCard = rawData.attachments[0].content.body;
    console.log("rawCard for survey", rawCard);

    let data ={
      heading : rawCard[0].label,
    }
    
    rawCard[1]["columns"].map(actionObj=>{
        console.log("rawCard1Result", actionObj);
    })
    console.log("SurveyChoicesData",this.choices);
    this.rawData = data;
  }
}
