import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class CustomStatusCardModel {
  public ActionSet: Array<string> = [];
  public selectedOption: number;
  public searchText: string;


  private parseActionSet(rawdata: any) {
    console.log("CustomStatusCardModel ~ parseActionSet ~ rawdata", rawdata)
    this.ActionSet = rawdata;
  }

  private parseStatusDetails(rawdata: any) {
    let v = rawdata.split(" : ");
    return { title: new DecoratedText(v[0]), value: v[1] };
  }

  private parseContainer(rawdata: any, cardType: string) {
    console.log("CustomStatusCardModel ~ parseContainer ~ rawdata", rawdata)
    let parsedData = {};

    parsedData["num"] = rawdata[0].columns[0].items[0].text; // tkt | rs number
    parsedData["div"] = rawdata[0].columns[1].items[0].text; // divider text
    parsedData["desc"] = rawdata[0].columns[2].items[0].text; // description
    parsedData["showMore"] = rawdata[1].actions[0].title; // Action Button title (Show More)

    let cardDetails = rawdata[1].actions[0].card["body"];
    let datas = [];
    let CardActions = [];

    cardDetails.forEach((el) => {
      if (el["type"] !== "ActionSet") {
        datas.push({ value: this.parseStatusDetails(el["text"]) });
      } else {
        el["actions"].forEach((actions) => {
          console.log("CustomStatusCardModel ~ el", actions);
          let act = {};
          // if (
          //   actions["title"] === "Add Comments" ||
          //   actions["title"] === "Cancel Incident"
          // ) {

          (act["title"] = actions["title"]), //  title
            (act["text"] = actions.card["body"][0]["label"]), //  text,
            (act["placeholder"] = actions.card["body"][0]["placeholder"]), //  placeholder
            (act["id"] = actions.card["body"][0]["id"]), //  id,
            (act["value"] = actions.card["body"][1]["actions"][0]["data"]), //value
            (act["requiredMsg"] = actions.card["body"][0]["errorMessage"]), // requiredMsg
            (act["btnTitle"] = actions.card["body"][1]["actions"][0]["title"]); // btn title
          CardActions.push(act);
          // }
        });
      }
    });


    parsedData["datas"] = datas; // Status details
    parsedData["cardActions"] = CardActions; // Action Set (AddComments/Cancel datas)
    console.log(
      "CustomStatusCardModel ~ parseContainer ~ parsedData",
      parsedData
    );

    return parsedData;
  }


  markOption(idx: number){
    console.log("CustomStatusCardModel ~ markOption ~ idx", idx)
    this.selectedOption = idx;
    console.log("CustomStatusCardModel ~ markOption ~ this.selectedOption", this.selectedOption)
}


  formatTime(time: Date) {
    let hours: number = time.getHours();
    let ampm: string = (hours >= 12) ? 'pm' : 'am';
    let minutes: number = time.getMinutes();
    let fMins: string = (minutes < 10) ? `0${minutes}` : minutes.toString();

    // the hour '0' should be '12'
    hours = (hours % 12) ? (hours % 12) : 12;

    return `${hours}:${fMins} ${ampm}`;
}
  constructor(
    public cardData: any,
    public timestamp: any,
    public cardType: string
  ) {
    let rawData = cardData.attachments[0].content.body;
    this.timestamp=this.formatTime(timestamp)
    let data = [];
    rawData.forEach((element) => {
      if (element["type"] === "Container") {
        data.push(this.parseContainer(element["items"], cardType));
      } else {
        if (element["type"] === "ActionSet") {
          this.parseActionSet(element["actions"]);
        }
      }
    });
    this.cardData = data;
  }
}
