import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-feedback-comment',
  templateUrl: './feedback-comment.component.html',
  styleUrls: ['./feedback-comment.component.css']
})
export class FeedbackCommentComponent implements OnInit{
  @Input() formStructure: any;
  @Output() formValue = new EventEmitter();
  ngOnInit(){
    console.log(this.formStructure)
}
}
