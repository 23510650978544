import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class ChoiceCardModel {
    
    public text: string|DecoratedText;
    public choices: Array<string> = [];
    public selectedOption: number; // will store the idx of the selected choice
    public shouldSendAsValue: boolean = false;
    public isOptionsEnabled: boolean = true;
  data: any;
    

    private parsePromptAndInit(rawdata: any){
        this.text = new DecoratedText(rawdata.message);
        this.choices = rawdata.rawData.split('|');
    }


    private parseCardAndInit(rawCard: any){
        if(rawCard.content.body){
            this.text = rawCard.content.body[0].text;
        }

        if(rawCard.content.actions){
            if(rawCard.content.actions[0].type == "Action.Submit"){
                this.shouldSendAsValue = true;
            }
            
            this.choices = rawCard.content.actions.map( actionObj => {
                return actionObj.title;
            } );
        }
    }


    markOption(idx: number){
        console.log("ChoiceCardModel ~ markOption ~ idx", idx)
        this.selectedOption = idx;
    }


    public reactToNewMessage(newMsg: ChatMessageModel){
        this.isOptionsEnabled = false;
    }


    constructor(message: any){
        if (message.attachments) {
            // Action card
            this.parseCardAndInit(message.attachments[0]);
        }else{
            // Not a card -> its a choice prompt
            this.parsePromptAndInit(message.channelData);
        }
    }

}
