import { ChatMessageModel } from "./chat-message-model";
import { DecoratedText } from "./DecoratedText";

export class KBCardDetailsModel {
  public text: string | DecoratedText;
  public divider: string | DecoratedText;
  // public kbarticleNumber: string | DecoratedText;
  // public warningMsg: string | DecoratedText;
  public choices: Array<object> = [];
  public selectedOption: number; // will store the idx of the selected choice
  public shouldSendAsValue: boolean = false;
  public isOptionsEnabled: boolean = false;


  // private parsePromptAndInit(rawCard: any) {
  //   console.log( "KBCardDetailsModel ~ parsePromptAndInit  ~ rawCard", rawCard);
    
  //   rawCard.map(actionObj1 => {
  //     if (actionObj1.type === "TextBlock") {
  //       let upperContent = {
  //         heading : actionObj1[0].text,
  //         headingLine : actionObj1[1].text
  //       };
  //       this.choices.push(upperContent);
  //       console.log("this choices", this.choices)
  //     }
  //   });
  // }

  // private parseContainer(rawdata: any) {
  //   console.log("KBCardDetailsModel ~ parsePromptAndInit ~ rawdata", rawdata);
  //   let parsedData = {};
  
  //   rawdata.map((obj)=>{
  //     if(obj.type === "TextBlock"){
  //       console.log("obj.text",obj.text)
  //       parsedData["text"] = obj.text;
  //       // return obj.text
  //     }
  //     else if(obj.type === "Image"){
  //       console.log("obj.url",obj.url)
  //       parsedData["url"] = obj.url 
  //       parsedData["title"] = obj.title 
  //       // return obj.url
  //     }
  //   })
  //   // parsedData["text"] = rawdata.items[0].text; // tkt | rs number
  //   // parsedData["url"] = rawdata.items[1].text; 
  //   console.log(
  //     "CustomStatusCardModel ~ parseContainer ~ parsedData",
  //     parsedData
  //   );
    
    
  //   return parsedData;
  //   // if (rawCard.content.body[0].items[2].items) {
  //   //   console.log(
  //   //     "KBCardDetailsModel ~ parseCardAndInit ~ rawCard.content.body[1].actions",
  //   //     rawCard.content.body[1].columns[1].items[0].actions
  //   //   );
  //   //   let data = [];
  //   //   rawCard.content.body[0].items[2].items.forEach((at) => {
  //   //     console.log("KBCardDetailsModel ~ parseCardAndInit ~ at",at);
  //   //     data.push(at);
  //   //     console.log("first array", at)
  //   //     at.forEach((element)=>{
  //   //       if(element["type"] === "Container")
  //   //       data.push(element["items"]);
  //   //       console.log("first array", element["items"])
  //   //     })

  //   //   });
  //   //   this.choices = data;
  //   //   console.log("KBCardDetailsModel ~ parseCardAndInit ~ choices",this.choices);


  //   //   if (rawCard.content.body[1].columns[1].items[0].actions[0].type == "Action.Submit") {
  //   //     console.log("inennn");
  //   //     this.shouldSendAsValue = false;
  //   //   }
  //   // }
  // }

  markOption(idx: number) {
    this.selectedOption = idx;
  }

  public reactToNewMessage(newMsg: ChatMessageModel) {
    this.isOptionsEnabled = false;
  }
  formatTime(time: Date) {
    let hours: number = time.getHours();
    let ampm: string = (hours >= 12) ? 'pm' : 'am';
    let minutes: number = time.getMinutes();
    let fMins: string = (minutes < 10) ? `0${minutes}` : minutes.toString();

    // the hour '0' should be '12'
    hours = (hours % 12) ? (hours % 12) : 12;

    return `${hours}:${fMins} ${ampm}`;
}
  constructor(
    public rawData: any,
    public timestamp: any
    ) {

    this.timestamp=this.formatTime(timestamp)
    // let titleData = rawCard.content.body[0].items;
    // let data = [];
    let rawCard = rawData.attachments[0].content.body[0].items;
    console.log("New rawdata detials", rawCard);

    // this.parsePromptAndInit(rawCard);

    // let rawData = rawCard.content.body[0].items[2].items;

    let data ={
      heading : rawCard[0].columns[0].items[0].text,
      kbNumber : rawCard[0].columns[2].items[0].text,
      headingLine : rawCard[1].text,
      datas: rawCard[2]["items"],
    }

    let newData = []
    console.log("KBCardModel ~ ", rawCard[2]["items"])

    rawCard[2]["items"].forEach((element) => {
      if (element.type == "Container") {
        console.log("KBCardModel - element", element)
        if(element["items"] != undefined && element["items"].length>0)
        {
          element["items"].forEach((element1: any) => {
            console.log("ELEMENT_DATA_IF", element1.type, element1)
            if (element1.type == "TextBlock") {
              let TextData = new DecoratedText(element1.text)
              console.log("KBCardModel IF ~ rawData[4]forEach ~ TextData", TextData)
              let sample1 = element1
              sample1["text"] = TextData
              newData.push(sample1)
            } else if (element1.type == "Image") {
              console.log("KBCardModel IF~ rawData[4]forEach ~ Image", element1)
              newData.push(element1)
            } else {
            //else if (!["TextBlock", "Image"].includes(element1.type)) {
            //else if (element1.type != "TextBlock" && element1.type != "Image") {
              if(element1["items"] != undefined && element1["items"].length>0)
              {
                element1["items"].forEach((element2: any) => {
                  console.log("ELEMENT_DATA_ELSE_1", element2)
                  if(element2["items"] != undefined && element2["items"].length>0)
                  {
                    element2["items"].forEach((element3: any) => {
                      console.log("ELEMENT_DATA_ELSE_IF_2", element3.type, element3)
                      if (element3.type == "TextBlock") {
                        console.log("KBCardModel ~ rawData[4] ~ newprint", element3)
                        console.log("KBCardModel ~ rawData[4] ~ newprinttype checktext in another loops", element3.text)
                        let TextData = new DecoratedText(element3.text)
                        console.log("KBCardModel ELSE IF ~ rawData[4]forEach ~ TextData", TextData)
                        let sample1 = element3
                        sample1["text"] = TextData
                        console.log("sample1 data::", sample1)
                        console.log("text data::", Text)
                        newData.push(sample1)
                      }
                      if (element3.type == "Image") {
                        console.log("KBCardModel ELSE IF ~ rawData[4]forEach ~ Image", element3)
                        newData.push(element3)
                      }
                    })
                  }else {
                      console.log("ELEMENT_DATA_ELSE_ELSE_1", element2.type, element2)
                      if (element2.type == "TextBlock") {
                        console.log("KBCardModel ~ rawData[4] ~ newprint", element2)
                        console.log("KBCardModel ~ rawData[4] ~ newprinttype checktext in another loops", element2.text)
                        let TextData = new DecoratedText(element2.text)
                        console.log("KBCardModel ELSE IF ~ rawData[4]forEach ~ TextData", TextData)
                        let sample1 = element2
                        sample1["text"] = TextData
                        console.log("sample1 data::", sample1)
                        console.log("text data::", Text)
                        newData.push(sample1)
                      }
                      if (element2.type == "Image") {
                        console.log("KBCardModel ELSE IF ~ rawData[4]forEach ~ Image", element2)
                        newData.push(element2)
                      }
                  }              
                })
              } 
            }
          });
        }
        // data.datas=new DecoratedText(element)
      }else if(element.type == "TextBlock"){
          console.log("KBCardModel ~ rawData[4] ~ newprint", element)
          console.log("KBCardModel ~ rawData[4] ~ newprinttype checktext in another loops", element.text)
          let TextData = new DecoratedText(element.text)
          console.log("KBCardModel ELSE IF ~ rawData[4]forEach ~ TextData", TextData)
          let sample1 = element
          sample1["text"] = TextData
          console.log("sample1 data::", sample1)
          console.log("text data::", Text)
          newData.push(sample1)
      }else if (element.type == "Image") {
        console.log("KBCardModel ELSE IF ~ rawData[4]forEach ~ Image", element)
        newData.push(element)
      }
    });

    data["datas"] = newData;
    console.log(JSON.stringify(newData) + "sample 123")
    this.rawData = data;  

  }
}
