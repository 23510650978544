import { ChatMessageModel } from "./chat-message-model";

export class TktStatusCardModel {
    public statusText: string;
    private _ticketStates = {
        1: "New",
        2: "In Progress",
        3: "On Hold",
        6: "Resolved",
        7: "Closed",
        8: "Cancelled"
    };

    resolveTktState() {
        this.statusText = this._ticketStates[this.stateNum];
    }

    constructor(
        public tktNumber: string,
        public shortDesc: string,
        public assignGroup: string,
        public assignTo: string,
        public lastUpdate: string,
        public stateNum: number | string,
    ) {
        if (isNaN(parseInt(status))) {
            this.statusText = stateNum.toString();
        } else {
            this.resolveTktState();
        }
    }
}
