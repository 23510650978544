import { ChatMessageModel } from "./chat-message-model";

export class NewTktCardModel {

    public tktNumber: string;
    public tktState: string;
    public tktTypeStr: string;
    public isNewIncCard: boolean;
    public shortDesc: string = '';
    public longDesc: string = '';
    public tktCategory: string = '';
    public tktPriority: string = '';
    public usrPhone: string = '';
    public assignGroup: string = '';
    public referenceTicket: string = '';
    public stage: string = '';
    public approval: string = '';
    public priority:string = '';
    public requestby:string = '';
    public requestedfor:string = '';
    public opened:string = '';
    public shortDescTitle:string=''
    public requetedforTitle:string=''
    public requestedbyTitle:string=''
    public openedTitle:string=''
    public commentsTitle:string=''
    public isButtonsShown: boolean = true;
    public isChildCard: boolean = false;
    public comments:string = '';

    private tktType: TicketType;


    private mapRawToModel(rawData: any){
        let dataList: Array<any> = rawData[0].content.body;

        switch (this.tktType) {
            case TicketType.Incident:
                this.tktTypeStr = "Incident";
                this.tktNumber = dataList[1].text.split(":")[1];
                this.shortDesc = dataList[2].text.split(":")[1];
                

                this.requestby=this.removeAttrName(this.getAttrFromObj(dataList[3], "text")).trim();
                this.requestedfor=this.removeAttrName(this.getAttrFromObj(dataList[4], "text")).trim();
                this.opened=this.removeAttrName(this.getAttrFromObj(dataList[5], "text"));
                this.comments=dataList[5].text.split("**")[2]
                this.tktState = dataList[2].text.split(':')[1].trim();
                this.tktCategory = this.removeAttrName(this.getAttrFromObj(dataList[4], "text"));
                this.tktPriority = this.removeAttrName(this.getAttrFromObj(dataList[5], "text"));
                // this.usrPhone = this.removeAttrName(this.getAttrFromObj(dataList[6], "text"));
                this.assignGroup = this.removeAttrName(this.getAttrFromObj(dataList[6], "text"));


                this.shortDescTitle = dataList[2].text.split('**')[1];
                this.requestedbyTitle=dataList[3].text.split('**')[1];

                this.requetedforTitle=dataList[4].text.split('**')[1];
                this.openedTitle=dataList[5].text.split('**')[1];
                this.commentsTitle=dataList[5].text.split('**')[1];
                break;
       
            case TicketType.ServiceRequest:
                this.isChildCard = true;
                this.tktTypeStr = "Incident";
                this.tktNumber = dataList[1].text.split(':')[1].trim();
                this.tktState = dataList[2].text.split(':')[1].trim();
                this.referenceTicket = this.removeAttrName(this.getAttrFromObj(dataList[7], "text"));
                this.shortDesc = this.removeAttrName(this.getAttrFromObj(dataList[3], "text"));
                this.tktCategory = this.removeAttrName(this.getAttrFromObj(dataList[4], "text"));
                this.tktPriority = this.removeAttrName(this.getAttrFromObj(dataList[5], "text"));
                // this.usrPhone = this.removeAttrName(this.getAttrFromObj(dataList[6], "text"));
                this.assignGroup = this.removeAttrName(this.getAttrFromObj(dataList[6], "text"));
                break;

            default:
                this.tktNumber = "Unrecognized Ticket!!"
                break;
        }
    }


    // Written to handle cases when obj is undefined.
    private getAttrFromObj(obj: any, attrName: string): any{
        if(!obj){
            return '';
        }else{
            return obj[attrName];
        }
    }


    // Removes the leading string before the first ':'
    private removeAttrName(str: string): string{
        if(!str || str.trim()==''){
            // Not a valid attr value
            return '-';
        }
        let arrTemp: Array<any>;
        arrTemp = str.split(':');
        arrTemp.splice(0,1);
        return arrTemp.join(':');
    }


    public reactToNewMessage(newMsg: ChatMessageModel){
        this.isButtonsShown = false;
    }
    

    // NOTE: rawData should only contain the "attachments" object from the original response.
    // "attachments" encapsulates the adaptive card schema.
    constructor(rawData: any, isNew: boolean, tktType: TicketType){
        this.isNewIncCard = isNew;
        this.tktType = tktType;
        this.mapRawToModel(rawData);
    }
}


export enum TicketType{
    Incident,
    ServiceRequest,
};
