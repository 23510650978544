import { Injectable } from '@angular/core';
import { Observable, fromEvent,BehaviorSubject } from 'rxjs';
import { DirectLine } from './botframework-directlinejs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  

  // Global values
  public username: string = "** Dev **";
  
  public resizeWidth$: Observable<any>;
  public resizeHeight$: Observable<any>;
  // private messageSource = new BehaviorSubject('default message');
  // currentMessage = this.messageSource.asObservable();
  public currHeight: number;
  public currWidth: number;
  public readonly mobileWidth: number = 900;
  public shouldShowGreeting: boolean = false;
  public isIframeShown: boolean = false;
  public urlIframe: string = "https://dev69569.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?v=1&sysparm_id=6370412e3771300054b6a3549dbe5d82&sysparm_link_parent=5d643c6a3771300054b6a3549dbe5db0&sysparm_catalog=undefined&sysparm_catalog_view=catalog_default&sysparm_view=catalog_default";
  public isActionMsgShown: boolean = false;
  public voiceSubscriptionDetails: any;
  public userDetails: any;
  public thumbnail: any;
  public isAgent: boolean;
  public authToken: string;

  myAuthToken:any;

  public dlObj: DirectLine;
   // private _http: any

  // public agentStatusCheck(){
  //   let agentAPIURL = `https://asecdddv1002.asedv001.appserviceenvironment.net/api/v2.0/admin/livechat/getsingleAgentstatus/` + this.dlObj.conversationId;
  //   console.log(this.dlObj.conversationId);
  //   console.log("agentAPIURL: ", agentAPIURL);
  //   // return this._http.post<any>(environment.authAPIURL, JSON.stringify(data));
  //   return this._http.get(agentAPIURL);
  // }


  private monitorWinResize(){
    // Observe Width
    this.resizeWidth$ = fromEvent(window, 'resize')
      .debounceTime(200)
      .map(() => window.innerWidth)
      .distinctUntilChanged()
      .startWith(window.innerWidth);

    this.resizeWidth$.subscribe(
      data => {
        this.currWidth = data;
        console.log("** Width resized", data);
      }
    );

    // Observe Height
    this.resizeHeight$ = fromEvent(window, 'resize')
      .debounceTime(200)
      .map(() => window.innerHeight)
      .distinctUntilChanged()
      .startWith(window.innerHeight);

    this.resizeHeight$.subscribe(
      data => {
        this.currHeight = data;
        console.log("** Height resized", data);
      }
    );
  }

  public setUserDetails(emailID: string, employeeID: string, mobile: string, city: string, state: string){
    this.userDetails = {
      email: emailID,
      userID: employeeID,
      contact: mobile,
      location: {
        city: city,
        state: state
      }
    };
  }

  public getUserDetails(){
    return this.userDetails;
  }

  setThumbnailDetails(graphToken: any, thumbnail: any) {
    this.thumbnail = {
      token: graphToken,
      thumbnailPath: thumbnail
    }
  }

  public getThumbnailDetails(){
    return this.thumbnail;
  }

  public setVoiceSubscriptionDetails(data: any){
    this.voiceSubscriptionDetails=data["voiceSubscription"];
  }

	public getVoiceSubscriptionDetails(){
    return this.voiceSubscriptionDetails;
  }

  public setAuthToken(data: any){
    console.log("AppStateService ~ setAuthToken ~ data", data)
    this.authToken=data;
  }

	public getAuthToken(){
    return this.authToken;
  }
  // changeMessage(message: string) {
  //   this.messageSource.next(message)
  // }

  
  constructor(
    private _http: HttpClient,
    ) {
    this.monitorWinResize();
  }
}