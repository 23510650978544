import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { Pnf404Component } from './pnf404/pnf404.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { SessionCloseComponent } from './session-close/session-close.component';
import { Login2Component } from './login2/login2.component';
import { LogOutComponent } from './log-out/log-out.component';

const routes: Routes = [
  { path: 'login', component: Login2Component,data: [{isProd: true}] },
  { path: 'logout', component: LogOutComponent ,data: [{isProd: true}]},
  { path: 'admin', component: DashboardComponent, children: [
    { path: 'home', component: DashboardHomeComponent ,data: [{isProd: true}] },
    { path: 'chat-box', component: ChatBoxComponent ,data: [{isProd: true}] }
  ]},
  { path: 'session-end', component: SessionCloseComponent,data: [{isProd: true}] },
  { path: '', redirectTo:'login', pathMatch:'full' },
  { path: '**', component: Pnf404Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }