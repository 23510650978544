import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppStateService } from './app-state.service';

 


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  [x: string]: any;

 

  private readonly urlLoginDetails = 'login/details';
  private readonly apiV2 = 'api/v2.0/';

 

  
  constructor(
    private _http: HttpClient,
    private _appStateService: AppStateService
  ) { }

 

 

 


  public fetchAuthInfo(sessionToken: string) {
    const url = environment.apiGatewayBaseUrl + this.apiV2+this.urlLoginDetails;
    const opt = {
      'headers': {
        'Content-Type': 'application/json',
        'csd-x-auth-token': sessionToken
      },
    };

 

    console.log('[fetchAuthInfo] Querying login at: ', url);
    console.log('[fetchAuthInfo] Options: ', opt);
    return this._http.get(url, opt);
  }

 
  public logoutUser(){
    let sessionToken =this._appStateService.myAuthToken
    console.log("AuthenticationService ~ logoutUser ~ sessionToken", sessionToken)
    let url = environment.apiGatewayBaseUrl + this.apiV2 + 'logout';
    const opt = {
      'headers': {
        'Content-Type': 'application/json',
        'csd-x-auth-token': sessionToken,
        'Access-Control-Allow-Origin': '*'
      }
    }
    return this._http.get(url,opt);
  }

  public fetchUserRole(digitalID: string) {
    const url = environment.platformServiceUrl+this.apiV2+'admin/userAgent/checkIfUserOrAgent/get/' + digitalID;

 

    console.log('[fetchUserRole] Querying login at: ', url);
    return this._http.get(url);
  }

 



}