import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: "app-form-adcard",
  templateUrl: "./form-adcard.component.html",
  styleUrls: ["./form-adcard.component.css"],
})
export class FormAdcardComponent implements OnInit {
  @Input() formStructure: any;
  @Output() formValue = new EventEmitter();

  public isFormReady: boolean = false;
  public formRows: Array<FormCardIpRow> = [];
  public enInputType = InputType;
  public errorTimer: any;
  public isErrorShown: boolean = false;
  buttonvalue: any = false;
  pageButton: any = [];
  public fieldValidationError: boolean = false;
  public fieldValidationMsg:string= "";

  parseAndInit(rawAdCard) {
    const cardBody: Array<any> = rawAdCard.content.body;
    console.log("FormAdcardComponent ~ parseAndInit ~ cardBody", cardBody);
    let ipRowLabel: string,
      ipControlData: any,
      ipRowInfo: string,
      ipControlType: InputType,
      ipControlName: string;

    // Loop through the object and parse into appropriate structures.
    for (let i in cardBody) {
      const item = cardBody[i];
      // console.log("FormAdcardComponent ~ parseAndInit ~ item", item)

      switch (item.type) {
        case "TextBlock":
          // Label or Info
          if (item.size && item.size == "small") {
            ipRowInfo = item.text;
          } else {
            if (parseInt(i) != 0) {
              // Push into array
              this.formRows.push(
                new FormCardIpRow(
                  ipRowLabel,
                  ipControlType,
                  ipControlName,
                  ipControlData,
                  ipRowInfo
                )
              );
              // Reset variables
              ipRowLabel =
                ipControlType =
                ipControlName =
                ipControlData =
                ipRowInfo =
                  null;
            }
            ipRowLabel = item.label;
          }
          this.formRows.push(
            new FormCardIpRow(
              ipRowLabel,
              ipControlType,
              ipControlName,
              ipControlData,
              ipRowInfo
            )
          );
          break;

        case "Input.Text":
          // console.log("inputTExt", item.maxLength);

          ipControlType = InputType.TextBox;
          ipControlName = item.id;
          ipRowLabel = item.label;
          ipControlData = item;
          this.formRows.push(
            new FormCardIpRow(
              ipRowLabel,
              ipControlType,
              ipControlName,
              ipControlData,
              ipRowInfo
            )
          );
          break;

        case "Input.ChoiceSet":
          ipControlType = InputType.Select;
          ipControlName = item.id;
          ipControlData = item;
          ipRowLabel = item.label;
          this.formRows.push(
            new FormCardIpRow(
              ipRowLabel,
              ipControlType,
              ipControlName,
              ipControlData,
              ipRowInfo
            )
          );
          break;
        case "ColumnSet":
          console.log("FormAdcardComponent ~ parseAndInit ~ ColumnSet");
          console.log(item.columns);
          item.columns.forEach((data: any) => {
            console.log(
              "FormAdcardComponent ~ item.columns.forEach ~ data",
              data.items[0].actions[0].title
            );
            this.pageButton.push(data.items[0].actions[0].title);
          });
          // ipControlType = InputType.Select;
          // ipControlName = item.id;
          // ipControlData = item;
          console.log(
            "FormAdcardComponent ~ item.columns.forEach ~  this.pageButton",
            this.pageButton
          );

          // this.formRows.push(new FormCardIpRow(ipRowLabel, ipControlType,ipControlName, ipControlData, ipRowInfo));
          break;
        default:
          break;
      }
      console.log(
        JSON.stringify(
          new FormCardIpRow(
            ipRowLabel,
            ipControlType,
            ipControlName,
            ipControlData,
            ipRowInfo
          )
        )
      );

      // Activate form
      this.isFormReady = true;
    }
  }

  private isAllFieldsFilled(formData) {  
    for (let keys in formData) {
      if (formData[keys] == "") {
        return false;
      }
    }
    return true 
  }

  private isValidateMaxlength(formData,validateDetails) {
     let v = 0;
     let id = "";
     let maxchar = 0;

     validateDetails.forEach((el) => {
       console.log("FormAdcardComponent ~ validateDetails.forEach ~ el", el)
       if (el.controlData.type === "Input.Text") {
         // check the maxLength with id
         if (formData[el.controlData.id].length > el.controlData.maxLength) {
           v = 1;
           id = el.controlData.id;
           maxchar = el.controlData.maxLength;           
         }
       }
     });
     return v == 0 ? { status: true } : { status: false, id:id,maxchar:maxchar };
  }

  submitForm(formData1, itemDetails) {
    let formData = formData1.value;
    console.log("FormAdcardComponent ~ submitForm ~ itemDetails", itemDetails);
    console.log("FormAdcardComponent ~ submitForm ~ formData", formData);

    if (this.isAllFieldsFilled(formData)) {
      let res = this.isValidateMaxlength(formData, itemDetails);
      if (res.status) {
        formData["button"] = this.pageButton[0].toLowerCase();
        this.formValue.emit(formData);
        formData1.form.reset();
        this.fieldValidationError = false;
        this.fieldValidationMsg="";
        this.buttonvalue = true;
      } else {        
        this.fieldValidationError = true;
        this.fieldValidationMsg = "maximum limit for " + res.id  + " - " + res.maxchar;
      }

      
    } else {
      this.showError();
      this.fieldValidationError = false;
      this.fieldValidationMsg = "";
    }
  }
  BackToMenu() {
    this.formValue.emit({ button: "backmenu", operationType: "backmenu" });
    console.log("FormAdcardComponent ~ BackToMenu ~ formValue", this.formValue);
  }

  // TODO: Handle form re-send!!
  showError() {
    clearTimeout(this.errorTimer);
    this.isErrorShown = true;

    this.errorTimer = setTimeout(() => {
      this.isErrorShown = false;
    }, 10000);
  }

  constructor() {}

  ngOnInit() {
    this.parseAndInit(this.formStructure);
  }
}


export class FormCardIpRow {
  public ddOptions: Array<DdOptionModel>;

  populateOptions(data) {
    const choices = data.choices;
    this.ddOptions = [];
    // Push the options into array.
    for (let i in choices) {
      this.ddOptions.push(new DdOptionModel(choices[i].title, choices[i].value));
    }
  }

  constructor(
    public controlLabel: string,
    public controlType: InputType,
    public controlName: string,
    public controlData: any,
    public controlInfo: string,
  ) {
    if (controlType == InputType.Select) {
      this.populateOptions(controlData);
    }
  };
}


export class DdOptionModel {
  constructor(
    public title: string,
    public value: string,
  ) { }
}


export enum InputType {
  TextBox,
  Select,
  TextArea,
}