/*-------------------------------------------------------*/
/* KP - dashboard-home component replacement */
/*-------------------------------------------------------*/

import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import {
  DirectLine,
  CardAction,
  IBotConnection,
  User,
  ConnectionStatus,
  Activity,
} from "../services/botframework-directlinejs";
import { SharedService } from "../services/sharedService";
import { DirectLineService } from "../services/directLineService";
import * as AdaptiveCards from "adaptivecards";
import { Action, SubmitAction } from "adaptivecards";
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {
  ChatMessageModel,
  MsgType,
  MsgAuthor,
} from "../models/chat-message-model";
import { NewTktCardModel, TicketType } from "../models/new-tkt-card-model";
import { TktStatusCardModel } from "../models/tkt-status-card-model";
import { InfoCardModel } from "../models/info-card-model";
import { isUndefined } from "util";
import {
  chatBubbleAnimation,
  botAvatarAnimation,
  cardItemAnimation,
  userAvatarAnimation,
  cardBtnAnimation,
} from "../utils/chat-animation";
import { FormattedTextModel } from "../models/formatted-text-model";
import { ChoiceCardModel } from "../models/choice-card-model";
import { ActionCardModel } from "../models/action-card-model";
import { OutageCardModel } from "../models/outage-card-model";
import { FormAdcardComponent } from "../form-adcard/form-adcard.component";
import {
  NewTktCardModelAD,
  TicketTypeAD,
} from "../models/new-tkt-card-model-AD";
import { AppStateService } from "../services/app-state.service";
import { DecoratedText, TextDecorationType } from "../models/DecoratedText";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { Subscription } from "rxjs";
import { I18nService } from "../services/i18n.service";
import {
  MultiContentCardModel,
  ContentType,
} from "../models/multi-content-card-model";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { PushNotificationsService} from 'ng-push';
import { CustomAdaptiveCardModel } from "../models/custom-adaptive-card-model";
import { CustomToggleAdaptiveCardModel } from "../models/custom-toggle-adaptive";
import { PlanetCustomToggleAdaptiveCardModel } from "../models/planet-custom-toggle-adaptive";
import { CustomStatusCardModel } from "../models/custom-status-card-model";
import { KBCardModel } from "../models/kb-article";
import { NewTktCardModelnew } from "../models/new-tkt-card-incidentstatus";
import { CustomSingleStatusCardModel } from "../models/custom-single-status-card-model";
import { CustomOnBehalfSRAdaptiveCardModel } from "../models/custom-onbehalf-sr-create-model";
import { kbcardAdaptiveCardModel } from "../models/kbartAdaptiveCardModel";
import { selfHeal2WebCardModel } from "../models/selfHeal2Web";
import { KbCardNewModel } from "../models/kb-article-new";
import { KBCardDetailsModel } from "../models/kb-article-details";
import { SurveyCardModel } from "../models/survey-card";  
import { FeedbackSurveyCardModel } from "../models/feedback-survey-card";
import { NegativeFeedbackSurveyCardModel } from "../models/negative-feedback-survey-card";

let self: ChatBoxComponent;

@Component({
  selector: "app-chat-box",
  templateUrl: "./chat-box.component.html",
  styleUrls: ["./chat-box.component.css"],
  animations: [
    chatBubbleAnimation,
    botAvatarAnimation,
    userAvatarAnimation,
    cardItemAnimation,
    cardBtnAnimation,
  ],
})
export class ChatBoxComponent implements OnInit, OnDestroy {
  public conversation: Array<ChatMessageModel> = [];
  private _conversationCustomIdLedger: Array<number> = [];
  private ackPendingIdxList: Array<number> = [];
  private reactPendingIdxList: Array<number> = [];
  private isCollectingInfoArticles: boolean = false;
  private _currConnState: ConnectionStatus;
  private subscription: Subscription = new Subscription();
  public userEmail: string;
  public contact: string;
  public userID: string;
  public attachmentFlag: boolean = false;
  public fileObj: any;
  public isFileUploaded: boolean;
  public choiceCardSelected: ChoiceCardModel;
  public choiceCardSelectedIdx: number;
  public choiceCardConversationIdx: number;
  public refreshAlertTimer: any = null;

  // Enums have been re-declared to be able to referenced in templates.
  public enMsgType = MsgType;
  public enMsgAuthor = MsgAuthor;
  public enTextDecorationType = TextDecorationType;
  public enMconTextType = ContentType;
  private _timerTokenRefresh;
  public i18nTexts: any;

  // The below three variables should only be set by the method showModalMsg()
  public _shouldShowModalMsg: boolean = false;
  public _modalMsgBody: string;
  public _modalMsgType: number;
  public _isTxtDisabled: boolean = false;
  private _scrollTimer: any;
  private _micTimer: any;
  public showselfHealData = false;  
  public isShowAticleCard = false; 
  public showselfHealDeviceData = false;
  public planetSecondButtonToggleDropDown = false; 
  public selectedLocation: any;

  public readonly statusCardDefaultCount: number = 3;
  public readonly infoCardDefaultCount: number = 3;
  public readonly outageCardDefaultCount: number = 3;

  /*-------------------------------------------------------*/
  /* Voice Recognition implementation */
  /*-------------------------------------------------------*/
  public isListening: boolean = false;
  public SpeechSDK;
  public reco: SpeechSDK.SpeechRecognizer;
  public voiceSubscriptionDetails: any;
  public searchText: string;

  // directLineObj: DirectLine;
  textToSpeechmsg: any;
  directLineObj: any;
  msgToBot: string;
  userName: string;
  authObj: any;
  geoLocationObj: any = { country: "Netherlands", city: "Amsterdam" };
  directLineToken: string;
  jwtToken: string = "";
  sysId: string = "";
  sendCalDate: boolean = true;
  CurrentFile: any;
  ImageSource: any;

  /**New variable */
  isMenuClicked: boolean = false;
  cardDatas: any;
  issubmitForm: boolean = false;
  isShowMoreClicked: boolean = false; 
  showMoreDetails: any;
  IsCommentsClicked: boolean = false;
  CommentsOptions: any = {};
  CurrentIndex: number = 0;
  IsShowKBClicked: boolean = false;
  KbarticleDatas: any = {};
  currentConvid: Number;
  CurrentAction: any = {
    convId: null,
  };
  disableButtonAction: any = {
    convId: null,
    isClicked: false,
  };
  fieldValidationError: boolean = false;
  fieldValidationMsg: string = "";
  ColorIndex: number;
  fiveticketIndex: number=null;
  singleTicketColor:number=null;
  statusFiveColor:number=null

  /**New variable */
  fetchParamsData(params: any) {
    let data = atob(params["data"] || "");
    if (data !== "") {
      self.authObj = JSON.parse(data);

      // Set usernames
      // self.userName = self.authObj.bot_user.substring(0, self.authObj.bot_user.lastIndexOf("@"));
      self.userName = self.authObj.bot_user;
      this._appStateService.username = self.userName;

      // Set tokens
      self.directLineToken = self.authObj.directLineToken;
      self.jwtToken = self.authObj.jwtToken;
    } else {
      this.router.navigate(["/login"]);
    }
  }

  subscribeMessages() {
    // Subscribe to all activities
    this.subscription.add(
      this.directLineObj.activity$.subscribe((data) => {
        console.log(" ** ACTIVITY:", data);
        this.singleTicketColor=null
        this.statusFiveColor=null
      })
    );

    // Handle Typing event
    this.subscription.add(
      this.directLineObj.activity$
        .filter((activity) => {
          return activity.type === "typing";
        })
        .subscribe((message) => {
          console.log("Identified typing event!!");
          this._appStateService.isActionMsgShown = true;
        })
    );

    // Handle messages from the USER.
    this.subscription.add(
      this.directLineObj.activity$
        .filter((activity) => {
          return (
            activity.type === "message" && activity.from.id === self.userName
          );
        })
        .subscribe((message) => {
          console.log("User msg: ", message);
          if (this.refreshAlertTimer != null) {
            clearTimeout(this.refreshAlertTimer);
          }
          let msgId: number = parseInt(message.id.split("|")[1]);
          let customId: number = message.channelData.customUserId;
          // TODO: Properly handle out-of-order messages.
          if (this._conversationCustomIdLedger.includes(customId)) {
            // The message is a fresh one. Acknowledge it!
            this.acknowledgeUserMessage(message);
          } else {
            // The message is from history.
            let newMsg: ChatMessageModel = new ChatMessageModel(
              MsgAuthor.User,
              message.text,
              MsgType.TextOnly,
              new Date(message.timestamp),
              false,
              false
            );
            this.addMsgToConversation(newMsg);
          }
        })
    );

    // Handle messages from the BOT.
    this.subscription.add(
      this.directLineObj.activity$
        .filter((activity) => {
          return (
            activity.type === "message" && activity.from.id !== self.userName
          );
        })
        .subscribe((message) => {
          console.log("Bot msg: ", message);
          let currMsgBody: any;
          let currMsgType: MsgType;
          let currMsgTime: Date = new Date(message.timestamp);
          if (message.widget) {
            this._sharedService.widgetController(message.widget);
          }
          this.ColorIndex=null;
          //this.textToSpeechClient(message.text)

          // Hide typing message
          this._appStateService.isActionMsgShown = false;

          if (message.attachments && message.channelData) {
            // Process Adaptive Cards
            let dataType = message.channelData.dataType;
            let rawAdaptiveCard = message.attachments;

            if (rawAdaptiveCard[0].content.body) {
              // switch (numDataBlocks) {
              switch (dataType) {
                case "CARD_INFO_RA":
                  // For KB Article
                  this._isTxtDisabled = true;
                  self.addInfoCardToConversation(rawAdaptiveCard, currMsgTime);
                  break;

                case "CARD_NEWS":
                  // For news
                  self.addInfoCardToConversation(rawAdaptiveCard, currMsgTime);
                  break;
                case "SINGLE_INCIDENT_CARD":
                  let isNew1: boolean = true;
                  let ticketType1: TicketType = TicketType.Incident;
                  let newTicketCard1 = new NewTktCardModelnew(
                    rawAdaptiveCard,
                    isNew1,
                    ticketType1
                  );
                  currMsgBody = newTicketCard1;
                  currMsgType = MsgType.NewTicketCardnew;
                  break;

                case "SINGLE_INCIDENT_CARD1":
                  // Status Card
                  currMsgType = MsgType.SingleStatusCard;
                  currMsgBody = self.renderSingleStatusCard(
                    rawAdaptiveCard[0].content,
                    "INC",
                    currMsgTime
                  );
                  // let rawCard = message.adaptiveCard
                  //   ? message.adaptiveCard
                  //   : message.attachments[0];
                  // currMsgType = MsgType.AdaptiveCard;
                  // currMsgBody = self.renderAdaptiveCard(rawCard.content);
                  break;

                case "SINGLE_RITM_CARD":
                  let isNew2: boolean = true;
                  let ticketType2: TicketType = TicketType.ServiceRequest;
                  let newTicketCard3 = new NewTktCardModelnew(
                    rawAdaptiveCard,
                    isNew2,
                    ticketType2
                  );
                  currMsgBody = newTicketCard3;
                  currMsgType = MsgType.NewTicketCardnew;
                  break;

                case "SINGLE_RITM_CARD1":
                  // Status Card
                  currMsgType = MsgType.SingleStatusCard;
                  currMsgBody = self.renderSingleStatusCard(
                    rawAdaptiveCard[0].content,
                    "RITM",
                    currMsgTime
                  );

                case "CARD_ITSM_INC_NEW":
                case "CARD_ITSM_INC_Existing":
                case "CARD_ITSM_SR_NEW":
                  // Single Incident/SR Card
                  let isNew: boolean =
                    dataType === "CARD_ITSM_INC_NEW" ||
                    dataType === "CARD_ITSM_SR_NEW"
                      ? true
                      : false;
                  let ticketType: TicketType =
                    dataType === "CARD_ITSM_SR_NEW"
                      ? TicketType.ServiceRequest
                      : TicketType.Incident;
                  let newTicketCard = new NewTktCardModel(
                    rawAdaptiveCard,
                    isNew,
                    ticketType
                  );
                  console.log("newTicketCard: ", newTicketCard);
                  currMsgBody = newTicketCard;
                  currMsgType = MsgType.NewTicketCard;
                  break;

                // case "CARD_ITSM_INC_NEW_AD":
                //   let isNewAD: boolean = (dataType === "CARD_ITSM_INC_NEW_AD" || dataType === "CARD_ITSM_SR_NEW") ? true : false;
                //   let ticketTypeAD: TicketTypeAD = (dataType === "CARD_ITSM_SR_NEW" ? TicketTypeAD.ServiceRequest : TicketTypeAD.Incident);
                //   let newTicketCardAD = new NewTktCardModelAD(rawAdaptiveCard, isNewAD, ticketTypeAD);
                //   console.log("newTicketCard: ", newTicketCardAD);
                //   currMsgBody = newTicketCardAD;
                //   currMsgType = MsgType.NewTicketCardAD;
                //   break;

                case "CARD_ITSM_INC_STATUS":
                  // Status Card
                  currMsgType = MsgType.StatusCardCollection;
                  currMsgBody = self.parseRawToStatusCards(rawAdaptiveCard);
                  break;
                case "CARD_ITSM_INC_STATUS_GROUP":
                  // Status Card
                  //currMsgType = MsgType.StatusCardCollection;
                  currMsgBody = self.parseRawToStatusCardsGroup(
                    rawAdaptiveCard,
                    currMsgTime
                  );
                  break;

                case "CARD_ITSM_OUTAGE":
                  // Outage Card
                  currMsgType = MsgType.OutageCard;
                  currMsgBody = new OutageCardModel(rawAdaptiveCard);
                  break;

                // TODO: Change the datatype of this card.
                case "CARD_ITSM_INC_UPDATECLOSE":
                  // Choice Card - with actions
                  currMsgType = MsgType.ChoiceCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "CARD_AD":
                  // Form card
                  currMsgType = MsgType.FormCard;
                  currMsgBody = rawAdaptiveCard[0];
                  break;

                case "CARD_FAQ":
                  // FAQ Card - with multiple content types
                  currMsgType = MsgType.FaqCard;
                  currMsgBody = new MultiContentCardModel(rawAdaptiveCard);
                  break;

                case "MENU_CARD":
                  // Form card
                  // let rawCard = message.adaptiveCard
                  // ? message.adaptiveCard
                  // : message.attachments[0];
                  // currMsgType = MsgType.AdaptiveCard;
                  // currMsgBody = self.renderAdaptiveCard(rawCard.content);
                  // console.log("innnnn1.20");
                  self.parseRawsToCustomToggleAdaptiveCards(
                    message,
                    currMsgTime
                  );
                  break;
                
                  case "WEBMENUCARD":
                    // Form card
                    console.log("PlanetMenuCard", rawAdaptiveCard);
                    
                    self.parseRawsToPlanetCustomToggleAdaptiveCards(
                      message,
                      currMsgTime
                    );
                    break;

                case "SERVICE_REQUEST":
                  //   let rawCard = message.adaptiveCard
                  // ? message.adaptiveCard
                  // : message.attachments[0];
                  // currMsgType = MsgType.AdaptiveCard;
                  // currMsgBody = self.renderAdaptiveCard(rawCard.content);
                  // console.log("........SERVICE REQUEST CARD........")
                  currMsgType = MsgType.SRCreate;
                  currMsgBody = rawAdaptiveCard[0];
                  break;

                  
                case "SINGLE_SERVICE_REQUEST":
               
                  self.onbehalfSRAdaptiveCard(
                    message,
                    currMsgTime
                  );
                  break;


                case "CREATE_INCIDENT_FORM_CARD":
                  // Form card
                  //   let rawCard = message.adaptiveCard
                  // ? message.adaptiveCard
                  // : message.attachments[0];
                  // currMsgType = MsgType.AdaptiveCard;
                  // currMsgBody = self.renderAdaptiveCard(rawCard.content);
                  console.log("Hard Reset Testing  Sep 19-3")
                  currMsgType = MsgType.FormCard;
                  currMsgBody = rawAdaptiveCard[0];
                  break;

                case "FIRST_FIVE_RITM_STATUS_CARD":
                  // Form card
                  self.parseRawsToCustomStatusAdaptiveCards(
                    message,
                    currMsgTime,
                    "RITM"
                  );

                  // currMsgType = MsgType.AdaptiveCard;

                  // currMsgBody = self.renderAdaptiveCard(
                  //   message.attachments[0].content
                  // );

                  break;
                case "FIRST_FIVE_RITM_STATUS_CARD2":
                  // Form card
                  self.parseRawsToCustomStatusAdaptiveCards(
                    message,
                    currMsgTime,
                    "RITM"
                  );
                  break;
                case "FIRST_FIVE_TICKET_STATUS_CARD":
                  self.parseRawsToCustomStatusAdaptiveCards(
                    message,
                    currMsgTime,
                    "INC"
                  );
                  // currMsgType = MsgType.AdaptiveCard;

                  // currMsgBody = self.renderAdaptiveCard(
                  //   message.attachments[0].content
                  // );

                  break;

                case "FIRST_FIVE_TICKET_STATUS_CARD2":
                  // Form card

                  self.parseRawsToCustomStatusAdaptiveCards(
                    message,
                    currMsgTime,
                    "INC"
                  );
                  // currMsgType = MsgType.AdaptiveCard;

                  // currMsgBody = self.renderAdaptiveCard(
                  //   message.attachments[0].content
                  // );

                  break;
                  case "KB_ARTICLE_CARD3":
                    // Form card
                    console.log("rawAdaptiveCard", rawAdaptiveCard);
                    
                    self.KBarticleNewCard(
                      message,
                      currMsgTime,
                      "INC"
                    );
                    // currMsgType = MsgType.AdaptiveCard;
  
                    // currMsgBody = self.renderAdaptiveCard(
                    //   message.attachments[0].content
                    // );
  
                    break;

                    case "KB_ARTICLE_CARD":
                      // Form card
                      console.log("KBCardDetailsCard", rawAdaptiveCard);
                      
                      self.KBCardDetailsCard(
                        message,
                        currMsgTime
                      );
                      // currMsgType = MsgType.AdaptiveCard;
    
                      // currMsgBody = self.renderAdaptiveCard(
                      //   message.attachments[0].content
                      // );
    
                      break;
                      case "SURVEY":
                        // Form card
                        console.log("SurveyCard", rawAdaptiveCard);
                        
                        self.SurveyCard(
                          message,
                          currMsgTime
                        );
                        break;
                        case "FEEDBACK_SERVAY":
                        // Form card
                        console.log("FeedbackSurvey", rawAdaptiveCard);
                        
                        self.FeedbackSurveyCard(
                          message,
                          currMsgTime
                        );
                        break;
                        case "FEEDBACK_COMMENT":
                          // Form card
                          console.log("FeedbackComment", rawAdaptiveCard);
                          
                          self.NegativeFeedbackSurveyCard(
                            message,
                            currMsgTime
                          );
                          break;
                // case "KB_ARTICLE_CARD":
                //   console.log("rawAdaptiveCard", rawAdaptiveCard);

                //   self.parseRawsToKBarticleAdaptiveCards(message, currMsgTime);

                //   break;
                  case "KB_ARTICLE_CARD1":
                    console.log("rawAdaptiveCard", rawAdaptiveCard);
                    self.kbarticlecardopenurl(
                      message,
                      currMsgTime
                    );

                    break;

                case "ALERT_CARD":
                  // FAQ Card - with multiple content types
                  currMsgType = MsgType.TextOnly;
                  currMsgBody = message.channelData.message;
                  // this.refreshAlertTimer = setTimeout((data) => {
                  //   this.postLogoutSuccess("success", this.onLogout);
                  // }, 600000);
                  break;

                case "CARD_ITSM_SR_STATUS":
                  // Form card
                  // let rawCard = message.adaptiveCard
                  // ? message.adaptiveCard
                  // : message.attachments[0];
                  // currMsgType = MsgType.AdaptiveCard;
                  // currMsgBody = self.renderAdaptiveCard(rawCard.content);

                  currMsgType = MsgType.StatusCardCollection;
                  currMsgBody = self.parseRawToStatusCards(rawAdaptiveCard);

                  break;

                  case "SELFHEAL2WEB":
                    console.log("selfHealData", rawAdaptiveCard);
                    self.SelfHeal2Web(
                      message,
                      currMsgTime
                    );

                    break;

                default:
                  // Unknown Card
                  currMsgType = MsgType.TextOnly;
                  currMsgBody =
                    "ERROR: " +
                    dataType +
                    " is not yet implemented!! Kindly report to the dev team." +
                    JSON.stringify(rawAdaptiveCard);
              }
            } else {
              currMsgType = MsgType.TextOnly;
              currMsgBody = rawAdaptiveCard[0].content.title;
            }
          } else if (
            (message.attachments || message.adaptiveCard) &&
            !message.channelData
          ) {
            // Adaptive cards without any channel-data
            // TODO: Remove the temporary solution below.
            let rawCard = message.adaptiveCard
              ? message.adaptiveCard
              : message.attachments[0];
            if (rawCard.content.actions) {
              // Action Card
              currMsgType = MsgType.ActionCard;
              currMsgBody = new ActionCardModel(rawCard);
            } else if (
              rawCard.contentType === "application/vnd.microsoft.card.video"
            ) {
              // Video Card
              currMsgType = MsgType.VideoCard;
              currMsgBody = rawCard.content.media[0].url;
            } else if (
              rawCard.contentType === "application/vnd.microsoft.card.adaptive"
            ) {
              currMsgType = MsgType.AdaptiveCard;
              currMsgBody = self.renderAdaptiveCard(rawCard.content);
            } else {
              // Error Message
              currMsgType = MsgType.TextOnly;
              currMsgBody =
                "ERROR: Unidentified attachment!! Kindly report to the dev team." +
                JSON.stringify(rawCard);
            }
          } else {
            // Text or Choice response
            if (message.channelData) {
              let dataType = message.channelData.dataType;

              switch (dataType) {
                case "TEXT_FORMATTED":
                  // Formatted Text
                  currMsgType = MsgType.TextFormatted;
                  currMsgBody = new FormattedTextModel(message.text);
                  break;

                case "YES_NO":
                  // Choice Card
                  currMsgType = MsgType.YesNoCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "YES_NO_ATTCH":
                  // Choice Card
                  currMsgType = MsgType.YesNoCardWithAttachment;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "UPLD_ATTCH":
                  // Choice Card
                  currMsgType = MsgType.UploadAttchCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "FEEDBACK_START":
                  // Feedback Card
                  currMsgType = MsgType.ThumbsCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "OTHER_CHOICE":
                  // Choice Card
                  this._isTxtDisabled = false;
                  currMsgType = MsgType.ChoiceCard;
                  currMsgBody = new ChoiceCardModel(message);
                  break;

                case "calendar":
                case "calender":
                  // Calendar Card
                  currMsgType = MsgType.Calender;
                  currMsgBody = message.text;
                  break;

                default:
                  // TODO: Remove this... temporary solution!!! Write an error instead.
                  // Plain Text
                  if (message.channelData.rawData) {
                    self.sysId = message.channelData.rawData;
                  }
                  currMsgType = MsgType.TextOnly;
                  currMsgBody = message.text;
                  break;
              }
            } else {
              // Plain Text
              currMsgType = MsgType.TextOnly;
              currMsgBody = message.text;
            }

            if (self.isCollectingInfoArticles) {
              self.isCollectingInfoArticles = false;
            }
          }

          if (!self.isCollectingInfoArticles) {
            let newMsg = new ChatMessageModel(
              MsgAuthor.Bot,
              currMsgBody,
              currMsgType,
              currMsgTime,
              false
            );
            self.addMsgToConversation(newMsg);
            if (currMsgType == MsgType.AdaptiveCard) {
              setTimeout(function () {
                let dom = document.getElementsByClassName("adaptive-card");
                dom[dom.length - 1].appendChild(currMsgBody);
                self.scrollToBottom();
              }, 1000);
            }
          }
          self.scrollToBottom();
        })
    );
  }

//SINGLE_SERVICE_REQUEST CARD
  private onbehalfSRAdaptiveCard(rawData: any, msgTime: Date) {
    console.log(
      "ChatBoxComponent ~ onbehalfSRAdaptiveCard ~ rawData",
      rawData
    );

    let newInfoCard = new CustomOnBehalfSRAdaptiveCardModel(rawData);  //updated the ts file(model)
    console.log(
      "ChatBoxComponent ~ onbehalfSRAdaptiveCard ~ newInfoCard",
      newInfoCard
    );

    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.SingleSRCreate, //updated the MsgType
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }

  //kbarticleopenurl
  private kbarticlecardopenurl(rawData: any, msgTime: Date) {
    console.log(
      "ChatBoxComponent ~ onbehalfSRAdaptiveCard ~ rawData",
      rawData
    );

    let newInfoCard = new kbcardAdaptiveCardModel(rawData);  //updated the ts file(model)
    console.log(
      "ChatBoxComponent ~ onbehalfSRAdaptiveCard ~ newInfoCard",
      newInfoCard
    );

    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.kbarticlecardopenurl, //updated the MsgType
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }

  //selfHeal
  private SelfHeal2Web(rawData: any, msgTime: Date) {
    console.log(
      "ChatBoxComponent ~ onbehalfSRAdaptiveCard ~ rawData",
      rawData
    );

    let newInfoCard = new selfHeal2WebCardModel(rawData);  //updated the ts file(model)
    console.log(
      "ChatBoxComponent ~ onbehalfSRAdaptiveCard ~ newInfoCard",
      newInfoCard
    );

    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.SelfHeal2Web, //updated the MsgType
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }






  // NOTE: The text from the text-area is stored in "msgToBot".
  sendMsgToBot() {
    if (!isUndefined(this.msgToBot) && this.msgToBot != "") {
      // self.checkDirectlineConnection();
      if (this._currConnState == ConnectionStatus.Online) {
        self.postMsgToBot(this.msgToBot);
      } else {
        console.log(
          "** ERROR: Connection interrupted! Cannot send messages to bot."
        );
      }

      // Clear the text-area
      this.msgToBot = "";
    }
  }

  startNewConversation(msg:string){
    this.msgToBot=msg;
    this.sendMsgToBot();
  }

  //render adaptive card
  private renderAdaptiveCard(rawdata: any) {
    // if(msg == 'Weather'){
    //   let response = self.getLocation(res=>{
    //     if(res == true){
    //       console.log("chatbot");
    //Hard Reset Testing  Sep 19-2
    console.log("Hard Reset Testing  Sep 19-2")
    console.log("before parsing:", rawdata);
    // Create an AdaptiveCard instance
    var adaptiveCard = new AdaptiveCards.AdaptiveCard();
    // Set its hostConfig property unless you want to use the default Host Config
    // Host Config defines the style and behavior of a card
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: "Segoe UI, Helvetica Neue, sans-serif",
      // More host config options
    });
    // Parse the card payload
    adaptiveCard.parse(rawdata);

    console.log("after parsing:", adaptiveCard);
    // Render the card to an HTML element:
    var renderedCard = adaptiveCard.render();

    adaptiveCard.onExecuteAction = function (action: Action) {
      if (action instanceof SubmitAction) {
        let buttons = document.getElementsByClassName("ac-pushButton");
        for (let i = 0; i < buttons.length; i++) {
          buttons[i]["disabled"] = true;
        }
        self.directLineObj
          .postActivity({
            from: { id: self.userName },
            type: "message",
            value: action.data,
          })
          .subscribe(
            (obs) => {
              console.log("form success");
            },
            (error) => {
              console.log(`ERROR: ${JSON.stringify(error)}`);
            }
          );
        console.log(JSON.stringify(action.data));
      }
    };

    return renderedCard;
    // And finally insert it somewhere in your page:
    // document.getElementById('main-body')?.appendChild(renderedCard);
  }

  // Handle Attachments
  public uploadFile(conversationIdx: number) {
    document.getElementById("inputFile").click();
  }

  // public resetFileInput() {
  //   (<HTMLInputElement>document.getElementById("inputFile")).value='';
  // }

  public FileChangeEvent(fileInput: any) {
    let reader = new FileReader();
    this.CurrentFile = fileInput.target.files[0];
    // reader.readAsDataURL(this.CurrentFile);
    reader.readAsDataURL(fileInput.target.files[0]);
    console.log(":::::", reader);
    // let file = URL.createObjectURL(this.CurrentFile)
    console.log(":::::this.CurrentFile", this.CurrentFile);

    reader.onload = (e) => {
      // if (this.CurrentFile.size < 240 * 1000) {
      if (this.CurrentFile.size) {
        let contentType = (reader.result as string)
          .split(",")[0]
          .split(";")[0]
          .split(":")[1]
          .split("/")[0];
        let usrmsg = (reader.result as string).split(",")[1];
        this.ImageSource = reader.result;
        let fileType = this.CurrentFile.type;
        let fileName = this.CurrentFile.name;
        if (contentType === "image") {
          // this.fileObj = (reader.result as string).split(",")[1];
          this.isFileUploaded = true;

          //new implementation

          let base64Img = reader.result;
          // let binaryImg = this.convertDataURIToBinary(base64Img);
          // let blob = new Blob([binaryImg], {type: this.CurrentFile.type});
          // let obj = {'base64':base64Img,
          //             'binary':binaryImg,
          //             'blob':blob
          //           }

          this.sendChoiceWithAttachment(base64Img, fileName, fileType);
          // self._sharedService.uploadFile(fileObj, self.CurrentFile.name, self.sysId).
          //   subscribe(
          //     resp => {
          //       console.log('This is the response from serivce ', resp.json());
          //       if (resp.json().success && resp.json().data.includes("error")) {
          //         alert("Oops, something went wrong!");
          //       } else if (resp.json().success) {
          //         alert("Image uploaded successfully!");
          //       } else {
          //         alert("Oops, something went wrong!");
          //       }
          //     })
        } else {
          // self.postedSuccessMsg("Invalid format. Try uploading in .jpg or .png format.");
          console.log("Invalid format. Try uploading in .jpg or .png format.");
        }
      } else {
        // self.postedSuccessMsg("File too large.");
        alert(
          "File is too large. Please upload a file with maximum size of 220 Kb"
        );
        console.log("File too large.");
      }
    };
  }

  clickedfromLeftMenu(msg: string) {
    // if(msg == 'Weather'){
    //   let response = self.getLocation(res=>{
    //     if(res == true){
    //       console.log("chatbot");
    //       self.msgToBot = msg;
    //       self.sendMsgToBot();
    //     }
    //     else{
    //       let currMsgType: MsgType;
    //       let currMsgTime: Date = new Date("2020-08-20T04:35:31.454Z")
    //       currMsgType = MsgType.TextOnly;
    //       msg = "Sorry, this functionality is only accessible if the required permissions are provided.";
    //       let newMsg = new ChatMessageModel(MsgAuthor.Bot, msg, currMsgType, currMsgTime, false);
    //       self.addMsgToConversation(newMsg);
    //       self.scrollToBottom();
    //       console.log("Bot msg: ", newMsg);
    //     }
    //   })
    //   console.log(response)
    // }
    // else{
    console.log("userClickedfromLeftmenu: ", msg);
    self.msgToBot = "//" + msg;
    self.sendMsgToBot();
    // }
  }

  getLocation(callback) {
    let locationFlag = false;
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(position);
          self.geoLocationObj = position;
          locationFlag = true;
          callback(locationFlag);
        },
        (error) => {
          switch (error.code) {
            case 1:
              console.log("Permission Denied");
              break;
            case 2:
              console.log("Position Unavailable");
              break;
            case 3:
              console.log("Timeout");
              break;
          }
          callback(false);
        }
      );
    }
  }

  sendChoiceResponse(
    card: ChoiceCardModel,
    selectedIdx: number,
    conversationIdx: number
  ) {
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.choices[selectedIdx]);
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(card.choices[selectedIdx], false);
        } else {
          self.postMsgToBot(card.choices[selectedIdx]);
        }
        card.markOption(selectedIdx);
      }
    }
  }

  selfHealToggle() {
    this.showselfHealData = !this.showselfHealData; 
  }

  selfHealDeviceToggle() {
    this.showselfHealDeviceData = !this.showselfHealDeviceData; 
  }

  sendSelfHeal2WebResponseForUserDevice(
    card: selfHeal2WebCardModel,
    selectedIdx: number,
    conversationIdx: number
  ) {
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.userDevices[selectedIdx]);
      let selectYesNo = card.userDevices[selectedIdx].split(' -')[0];
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(selectYesNo, false);
        } else {
          self.postMsgToBot(selectYesNo);
        }
        card.markOption(selectedIdx);
      }
      this.selfHealDeviceToggle();
      this.selfHealToggle();
    }
  }

  sendSelfHeal2WebResponse(
    card: selfHeal2WebCardModel,
    selectedIdx: number,
    conversationIdx: number
  ) {
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.choices[selectedIdx]);
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(card.choices[selectedIdx], false);
        } else {
          self.postMsgToBot(card.choices[selectedIdx]);
        }
        card.markOption(selectedIdx);
      }
      this.selfHealToggle();
    }
  }

  sendSelfHeal2WebResponseNo(
    card: selfHeal2WebCardModel,
    selectedIdx: number,
    conversationIdx: number
  ) {
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.choicesCondition2[selectedIdx]);
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(card.choicesCondition2[selectedIdx], false);
        } else {
          self.postMsgToBot(card.choicesCondition2[selectedIdx]);
        }
        card.markOption(selectedIdx);
      }
      this.selfHealToggle();
    }
  }

  showMenuItem(card: any, selectedIdx: number, conversationIdx: number) {




    console.log("ChatBoxComponent ~ showMenuItem ~ conversationIdx", conversationIdx)
    console.log("ChatBoxComponent ~ showMenuItem ~ selectedIdx", selectedIdx)
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card123", card)
    let selectedChoice1=card.choices[selectedIdx]
    console.log("** selectedChoice123: ", selectedChoice1);
    // let selectedChoice2=selectedChoice1["card"].actions[idx]


    console.log("ChatBoxComponent ~ showMenuItem ~ card", card);
    this.ColorIndex=selectedIdx;
    if (selectedChoice1.type == "Action.Submit") {
      this.isMenuClicked = false;
      this.issubmitForm = true;
      if (conversationIdx == this.conversation.length - 1) {
        console.log("** selectedChoicezs: ", selectedChoice1);
        if (this._currConnState == ConnectionStatus.Online) {
          // self.postMsgToBot(card.choices[selectedIdx]);

          let customMsgId: number;
          let newMsg: ChatMessageModel = new ChatMessageModel(
            MsgAuthor.User,
            selectedChoice1.data.value,
            MsgType.TextOnly,
            new Date(),
            false
          );
          customMsgId = this.addMsgToConversation(newMsg);
          self.scrollToBottom();
          self.directLineObj
            // .postActivity(
            //   this.createUserCustomMsgPayload(newMsg, customMsgId, selectedIdx)
            // )
            .postActivity({
              channelData: {
                authObj: self.authObj,
                geoLocation: self.geoLocationObj,
                jwtToken: self.jwtToken,
                customUserId: customMsgId,
                username: this._appStateService.username,
                email: self.authObj.email,
                employeeId: this.userID,
                contact: this.contact
              },
              from: { id: self.userName },
              type: "message",
              value: selectedChoice1["data"],
            })
            .subscribe(
              (obs) => {
                console.log("form success");
              },
              (error) => {
                console.log(`ERROR: ${JSON.stringify(error)}`);
              }
            );
          card.markOption(selectedIdx);
        }
      }
    } else {
      // console.log(this.cardDatas.title,card.title+"sdafafdsdsfa")
      if (this.cardDatas != undefined && this.cardDatas.title == card.title) {
        this.isMenuClicked = this.isMenuClicked ? false : true;
      } else {
        this.isMenuClicked = true;
      }
      card.markOption(selectedIdx);
    }

    this.cardDatas = { ...selectedChoice1, parentId: selectedIdx };
    this.currentConvid = conversationIdx;
    console.log(
      "ChatBoxComponent ~ showMenuItem ~ this.cardDatas",
      this.cardDatas
    );
    
  }
  sendCustomAdaptiveChoiceResponse(
    card: CustomToggleAdaptiveCardModel,
    selectedIdx: number,
    conversationIdx: number
  ) {
    console.log(
      "ChatBoxComponent ~ FileChangeEvent ~ selectedIdx",
      selectedIdx
    );
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card.choices[selectedIdx]);
      if (this._currConnState == ConnectionStatus.Online) {
        // self.postMsgToBot(card.choices[selectedIdx]);

        let customMsgId: number;
        let newMsg: ChatMessageModel = new ChatMessageModel(
          MsgAuthor.User,
          card.choices[selectedIdx]["title"],
          MsgType.TextOnly,
          new Date(),
          false
        );
        customMsgId = this.addMsgToConversation(newMsg);
        self.scrollToBottom();
        self.directLineObj
          // .postActivity(
          //   this.createUserCustomMsgPayload(newMsg, customMsgId, selectedIdx)
          // )
          .postActivity({
            channelData: {
              authObj: self.authObj,
              geoLocation: self.geoLocationObj,
              jwtToken: self.jwtToken,
              customUserId: customMsgId,
              username: this._appStateService.username,
              email: this.userEmail,
              employeeId: this.userID,
              contact: this.contact,
            },
            from: { id: self.userName },
            type: "message",
            value: card.choices[selectedIdx]["data"],
          })
          .subscribe(
            (obs) => {
              console.log("form success");
            },
            (error) => {
              console.log(`ERROR: ${JSON.stringify(error)}`);
            }
          );
        card.markOption(selectedIdx);
      }
    }
  }

  sendCustomToggleAdaptiveChoiceResponse(
    card: CustomToggleAdaptiveCardModel,
    selectedIdx: number,
    conversationIdx: number,idx:number
  ) {
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card123", card)
    let selectedChoice1=card.choices[selectedIdx]
    console.log("** selectedChoice123: ", selectedChoice1);
    let selectedChoice2=selectedChoice1["card"].actions[idx]

    this.isMenuClicked = false;
    
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card", selectedChoice2["data"].value);

    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedChoice: ", card);
      if (this._currConnState == ConnectionStatus.Online) {
        // self.postMsgToBot(card.choices[selectedIdx]);

        let customMsgId: number;
        let newMsg: ChatMessageModel = new ChatMessageModel(
          MsgAuthor.User,
          selectedChoice2["data"].value,
          MsgType.TextOnly,
          new Date(),
          false
        );
        customMsgId = this.addMsgToConversation(newMsg);
        self.scrollToBottom();
        self.directLineObj
          // .postActivity(
          //   this.createUserCustomMsgPayload(newMsg, customMsgId, selectedIdx)
          // )

          .postActivity({
            channelData: {
              authObj: self.authObj,
              geoLocation: self.geoLocationObj,
              jwtToken: self.jwtToken,
              customUserId: customMsgId,
              username: this._appStateService.username,
              email: self.authObj.email,
              employeeId: this.userID,
              contact: this.contact,
            },
            from: { id: self.userName },
            type: "message",
            value:  selectedChoice2["data"],
          })
          .subscribe(
            (obs) => {
              console.log("form success");
            },
            (error) => {
              console.log(`ERROR: ${JSON.stringify(error)}`);
            }
          );
        card.markOption(selectedIdx);
      }
    }
  }
  copyText(val: string) {
    console.log(val);
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  // toggleFileUpload(
  //   card: ChoiceCardModel,
  //   selectedIdx: number,
  //   conversationIdx: number
  // ) {
  //   if(selectedIdx == 0){
  //     this.attachmentFlag = !this.attachmentFlag;
  //     this.choiceCardSelected =  card
  //     this.choiceCardSelectedIdx =  selectedIdx
  //     this.choiceCardConversationIdx = conversationIdx
  //     if (this.isFileUploaded) {
  //       this.sendChoiceWithAttachment(
  //         card,
  //         selectedIdx,
  //         conversationIdx
  //       )
  //     }
  //   }
  //   else{
  //     this.sendChoiceResponse(card, selectedIdx, conversationIdx);
  //   }

  // }

  sendChoiceWithAttachment(base64Form: any, fileName: string, fileType: any) {
    if (this._currConnState == ConnectionStatus.Online) {
      // First add the user message to the conversation list, and then POST it.
      let customMsgText = this.ImageSource;
      let userMsg = "test";
      let customMsgId: number;
      let newMsg: ChatMessageModel = new ChatMessageModel(
        MsgAuthor.User,
        customMsgText,
        MsgType.TextOnly,
        new Date(),
        true
      );
      let customMsg: any = newMsg;
      // customMsg.resultObj = base64Form
      customMsgId = this.addMsgToConversation(customMsg);
      self.scrollToBottom();

      // POST the message.
      self.directLineObj
        .postActivity(
          this.createUserMsgPayloadWithAttachment(
            userMsg,
            customMsgId,
            base64Form,
            fileType,
            fileName
          )
        )
        .subscribe(
          (message) => {
            console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);

            // Handle resending
            if (!message.includes("|")) {
              // Show resend icons for all pending messages.
              console.log("Retry sending the message!!");
              this.flagPendingMsgsForRetry();
            }
            return;
          },
          (error) => {
            console.log(`** postMsgToBot ERROR: ${JSON.stringify(error)}`);

            // Acknowledge the message receipt as NOT sent and show retry button.
            // self.postedErrorMsg(usrmsg);
            return;
          }
        );
    }
  }

  createUserMsgPayloadWithAttachment(
    msg: string,
    customMsgId: number,
    base64Form: any,
    fileType: string,
    fileName: string
  ) {
    return {
      from: { id: self.userName },
      type: "message",
      text: "test",
      fileType: fileType,
      name: fileName,
      channelData: {
        authObj: self.authObj,
        geoLocation: self.geoLocationObj,
        jwtToken: self.jwtToken,
        customUserId: customMsgId,
        username: this._appStateService.username,
        email: self.authObj.email,
        employeeId: this.userID,
        contact: this.contact,
      },
      attachments: [
        {
          name: fileName,
          contentType: fileType,
          contentUrl: base64Form,
        },
      ],
    };
  }

  private checkDirectlineConnection() {
    this.subscription.add(
      self.directLineObj.connectionStatus$.subscribe((connectionStatus) => {
        this._currConnState = connectionStatus;
        console.log("** Connection Status: ", connectionStatus);

        switch (connectionStatus) {
          case ConnectionStatus.Uninitialized:
            // self._modalMsgBody = null;
            this.showModalMsg(1, "Trying to connect...");
            break;

          case ConnectionStatus.Connecting:
            // self._modalMsgBody = null;
            this.showModalMsg(1, "Connecting...");
            break;

          case ConnectionStatus.Online:
            // self._modalMsgBody = null;
            // this.showHideModalMsg(1, "Connected!!");
            this.showModalMsg(1, "Connected!!");
            this.hideModalMsg();
            break;

          case ConnectionStatus.ExpiredToken:
            this.showModalMsg(2, "Chat Session Expired! Please login again.");
            this.router.navigate(["/login"]);
            break;

          case ConnectionStatus.FailedToConnect:
            this.showModalMsg(2, "Please logout and login again.");
            this.router.navigate(["/login"]);
            break;

          case ConnectionStatus.Ended:
            this.showModalMsg(2, "Session expired! Please login again.");
            break;

          default:
            break;
        }

        console.log("** Connection MSG:\n", self._modalMsgBody);
      })
    );
  }
  private parseRawsToCustomAdaptiveCards(rawData: any, msgTime: Date) {
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomAdaptiveCards ~ rawData",
      rawData
    );
    // Parse raw into info-card model
    let newInfoCard = new CustomAdaptiveCardModel(rawData);
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomAdaptiveCards ~ newInfoCard",
      newInfoCard
    );

    console.log(
      "ChatBoxComponent ~ parseRawsToCustomAdaptiveCards ~ self.isCollectingInfoArticles",
      self.isCollectingInfoArticles
    );
    // if (self.isCollectingInfoArticles) {
    //   // Add the new item to last item of conversation
    //   self.conversation[self.conversation.length - 1].msgBody.push(newInfoCard);
    // } else {
    // Create a new entry in conversation
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.customAdaptiveCard,
      msgTime,
      false
    );
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomAdaptiveCards ~ newMsg",
      newMsg
    );
    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }

  private parseRawsToCustomToggleAdaptiveCards(rawData: any, msgTime: Date) {
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomToggleAdaptiveCards ~ rawData",
      rawData
    );

    let newInfoCard = new CustomToggleAdaptiveCardModel(rawData);
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomToggleAdaptiveCards ~ newInfoCard",
      newInfoCard
    );

    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.customToggleAdaptiveCard,
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }

  private parseRawsToPlanetCustomToggleAdaptiveCards(rawData: any, msgTime: Date) {
    this.planetSecondButtonToggleDropDown = false;
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomToggleAdaptiveCards ~ rawData",
      rawData
    );
    let newInfoCard = new PlanetCustomToggleAdaptiveCardModel(rawData);
    console.log(
      "ChatBoxComponent ~ parseRawsToCustomToggleAdaptiveCards ~ newInfoCard",
      newInfoCard
    );
    this.selectedLocation = undefined;
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.PlanetcustomToggleAdaptiveCard,
      msgTime,
      false
    );
    console.log(
      "ChatBoxComponent ~ parseRawsToPlanetCustomAdaptiveCards ~ newMsg",
      newMsg
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }

  private parseRawsToCustomStatusAdaptiveCards(
    rawData: any,
    msgTime: Date,
    cardType: string
  ) {
    let newInfoCard = new CustomStatusCardModel(rawData, msgTime, cardType);
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.customStatusCard,
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }
  private KBarticleNewCard(
    rawData: any,
    msgTime: Date,
    cardType: string
  ) {
    let newInfoCard = new KbCardNewModel(rawData, msgTime, cardType);
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.KBarticleNewCard,
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }
  
  private KBCardDetailsCard(
    rawData: any,
    msgTime: Date
  ) {
    let newInfoCard = new KBCardDetailsModel(rawData, msgTime);
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.KBCardDetailsCard,
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }
  private SurveyCard(
    rawData: any,
    msgTime: Date
  ) {
    let newInfoCard = new SurveyCardModel(rawData, msgTime);
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.SurveyCard,
      msgTime,
      false
    );

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    // }
  }

  private FeedbackSurveyCard( rawData: any,
    msgTime: Date){
      let newInfoCard = new FeedbackSurveyCardModel(rawData, msgTime);
      let newMsg = new ChatMessageModel(
        MsgAuthor.Bot,
        newInfoCard,
        MsgType.FeedbackSurveyCard,
        msgTime,
        false
      );
  
      self.addMsgToConversation(newMsg);
      self.isCollectingInfoArticles = true; // Reset when next text message is received.
      // }
    }
    private NegativeFeedbackSurveyCard( rawData: any,
      msgTime: Date){
        let newInfoCard = new NegativeFeedbackSurveyCardModel(rawData, msgTime);
        let newMsg = new ChatMessageModel(
          MsgAuthor.Bot,
          newInfoCard,
          MsgType.NegativeFeedbackSurveyCard,
          msgTime,
          false
        );
    
        self.addMsgToConversation(newMsg);
        self.isCollectingInfoArticles = true; // Reset when next text message is received.
        // }
      }
  private parseRawsToKBarticleAdaptiveCards(rawData: any, msgTime: Date) {
    console.log(
      "ChatBoxComponent ~ parseRawsToKBarticleAdaptiveCards ~ rawData",
      rawData
    );
    let newInfoCard = new KBCardModel(rawData, msgTime);
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      newInfoCard,
      MsgType.KBarticleCard,
      msgTime,
      false
    );
    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
  }

  public handleKbArticle(details: any, kbnumber: any, idx: number) {
    if (this.KbarticleDatas.kbnumber == kbnumber) {
      this.IsShowKBClicked = this.IsShowKBClicked ? false : true;
    } else {
      this.IsShowKBClicked = true;
    }
    this.KbarticleDatas = { details: details.datas, kbnumber: kbnumber };
    console.log("ChatBoxComponent ~ handleKbArticle ~ details", details.datas);
  }
  public handleShowMore(
    card: CustomStatusCardModel,
    cardActions: any,
    idx: number,
    convidx: number
  ) {
    this.fiveticketIndex=idx

    console.log("ChatBoxComponent ~ card", card)
    console.log("ChatBoxComponent ~ idx", idx)
    console.log("ChatBoxComponent ~ details", card["cardData"][idx].datas)
    if (this.CurrentIndex == idx) {
      this.showMoreDetails = {
        cardActions: cardActions,
        details: card["cardData"][idx].datas,
        convidx: convidx,
      };
      this.isShowMoreClicked = this.isShowMoreClicked ? false : true;
      this.IsCommentsClicked = false;
      this.currentConvid = convidx;
     

    } else {
      this.showMoreDetails = {
        cardActions: cardActions,
        details: card["cardData"][idx].datas,
        convidx: convidx,
        
      };
      
      this.isShowMoreClicked = true;
      this.IsCommentsClicked = false;
    }
    this.CurrentIndex = idx;
    
    // card.markOption(idx);
    console.log("ChatBoxComponent ~ handleShowMore ~ details", card["cardData"][idx].datas);
  }
  public handleClickHere(
    card: KbCardNewModel,
    selectedIdx: number,
    conversationIdx: number
  ) {
    console.log("ChatBoxComponent ~ FileChangeEvent ~ card KbCardNewModel", card)
    if (conversationIdx == this.conversation.length - 1) {
      console.log("** selectedValue: ", card.cardData[selectedIdx].desc);
      this.isShowAticleCard = false;
      if (this._currConnState == ConnectionStatus.Online) {
        if (card.shouldSendAsValue) {
          self.postMsgToBot(card.cardData[selectedIdx].desc, false);
        } else {
          self.postMsgToBot(card.cardData[selectedIdx].desc);
        }
        card.markOption(selectedIdx);
      }
  }
}

handleClickHereButton(
  card: KbCardNewModel,
  selectedIdx: number,
  conversationIdx: number
) {
  console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
  if (conversationIdx == this.conversation.length - 1) {
    console.log("** selectedChoice: ", card.choices[selectedIdx]);
    if (this._currConnState == ConnectionStatus.Online) {
      if(card.choices[selectedIdx] === "Show Previous Articles"){
        this.isShowAticleCard = !this.isShowAticleCard; 
        console.log("inside Show Previous Articles condition")
      }
      else if (card.shouldSendAsValue) {
        console.log("outside Show Previous Articles condition")
        self.postMsgToBot(card.choices[selectedIdx], false);
      } else {
        self.postMsgToBot(card.choices[selectedIdx]);
      }
      card.markOption(selectedIdx);
    }
  }
}
handleSurveyButton(
  card: SurveyCardModel,
  selectedIdx: number,
  conversationIdx: number
) {
  // this.fiveticketIndex=selectedIdx
  console.log("selectedIdx",selectedIdx);
  console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
  if (conversationIdx == this.conversation.length - 1) {
    const updatedTextValue = card.choices[selectedIdx]["value"];
    console.log("** selectedChoice: ",updatedTextValue);
    if (this._currConnState == ConnectionStatus.Online) {
      if (card.shouldSendAsValue) {
        self.postMsgToBot(updatedTextValue, false);
      } else {
        self.postMsgToBot(updatedTextValue);
      }
      card.markOption(selectedIdx);
    }
  }
}
handleFeedbackSurveyButton(
  card: FeedbackSurveyCardModel,
  selectedIdx: number,
  conversationIdx: number
) {
  // this.fiveticketIndex=selectedIdx
  console.log("selectedIdx",selectedIdx);
  console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
    const updatedTextValue = card.choices[selectedIdx]["value"];
    console.log("** selectedChoice: ",updatedTextValue);
    if (this._currConnState == ConnectionStatus.Online) {
      if (card.shouldSendAsValue) {
        self.postMsgToBot(updatedTextValue, false);
      } else {
        self.postMsgToBot(updatedTextValue);
      }
      card.markOption(selectedIdx);
    }
}

handlePlanetFirstButton(
  card: PlanetCustomToggleAdaptiveCardModel,
  selectedIdx: number,
  conversationIdx: number
) {
  // this.fiveticketIndex=selectedIdx
  console.log("selectedIdx",selectedIdx);
  console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
  if (conversationIdx == this.conversation.length - 1) {
    const updatedTextValue = card.firstButton[selectedIdx];
    console.log("** selectedChoice: ",updatedTextValue);
    if (this._currConnState == ConnectionStatus.Online) {
      if (card.shouldSendAsValue) {
        self.postMsgToBot(updatedTextValue, false);
      } else {
        self.postMsgToBot(updatedTextValue);
      }
      card.markOption(selectedIdx);
    }
  }
}

handlePlanetSecondButtonToggle() {
  this.planetSecondButtonToggleDropDown = !this.planetSecondButtonToggleDropDown;
}

handlePlanetSubmitLocation(
  card: PlanetCustomToggleAdaptiveCardModel,
  selectedIdx: number,
  conversationIdx: number
) {
  // this.fiveticketIndex=selectedIdx
  console.log(this.selectedLocation);
  console.log("selectedIdx",selectedIdx);
  console.log("ChatBoxComponent ~ FileChangeEvent ~ card", card)
  if (conversationIdx == this.conversation.length - 1 && this.selectedLocation !== undefined) {
    const updatedTextValue = this.selectedLocation;
    console.log("** selectedChoice: ",updatedTextValue);
    if (this._currConnState == ConnectionStatus.Online) {
      if (card.shouldSendAsValue) {
        self.postMsgToBot(updatedTextValue, false);
      } else {
        self.postMsgToBot(updatedTextValue);
      }
      card.markOption(selectedIdx);
    }
  }
}

  public BehalfBack(action: any, selectedIdx: number, conversationIdx: number){
    if(action.type=="Action.Submit"){
      console.log("BehalfBack ~ card", action)
      action['data'].button
  this.postMsgToBot({button: "backmenu", operationType: 'backmenu'}, false);
    }
  
    
  }
  public handleOptions(details: any) {
    this.IsCommentsClicked =this.IsCommentsClicked &&this.CommentsOptions["title"]! == details["title"]
        ? false
        : true;
    this.CommentsOptions = details;
    document.getElementById('addComments')["value"] = ''
    console.log("ChatBoxComponent ~ handleOptions ~ details", details);
  }

  public handleActionsOptions(details: any, ConvId: any,idx:any) {
    this.singleTicketColor=idx;
    console.log("ChatBoxComponent ~ handleActionsOptions ~ idx", idx)
    console.log("ChatBoxComponent ~ handleActionsOptions ~ details", details)
    if (details["title"] != "Back To Menu") {
      console.log("ChatBoxComponent ~ handleActionsOptions ~ details", details);
      // toggle the state - Add comments and cancel
      if (!this.CurrentAction.convId) {
        console.log(
          "this.CurrentAction",
          this.CurrentAction.hasOwnProperty("details")
        );
        this.CurrentAction = { details, convId: ConvId };
      } else {
        document.getElementById('addComments')["value"] = ''
        console.log("resett");
        if (this.CurrentAction.hasOwnProperty("details")) {
          if (this.CurrentAction.details.title !== details.title) {
            this.CurrentAction = { details, convId: ConvId };
          } else {
            this.CurrentAction = { convId: null };
          }
        } else {
          this.CurrentAction = { details, convId: ConvId };
        }
      }
    } else {
      //Back to menu
      this.postMsgToBot(details.value.msteams.value, false);
      this.CurrentAction = { convId: null }; // Reset
    }
  }
  public AddCommentsSubmit(textAreaValue: string, val: any, convx: number) {
    console.log(
      "ChatBoxComponent ~ AddCommentsSubmit ~ textAreaValue",
      textAreaValue,
      val
    );
   
    if (textAreaValue != "") {
      this.fieldValidationError = false;
      let FormData = {
        // ...val["value"],
        [val["value"]["msteams"]["value"]]: textAreaValue,
      };
      console.log("ChatBoxComponent ~ AddCommentsSubmit ~ FormData", FormData);

      if (this._currConnState == ConnectionStatus.Online) {
        // self.postMsgToBot(card.choices[selectedIdx]);

        let customMsgId: number;
        let newMsg: ChatMessageModel = new ChatMessageModel(
          MsgAuthor.User,
          `${
            val["value"]["msteams"]["value"].split(" ")[1]
          } - ${textAreaValue}`,
          MsgType.TextOnly,
          new Date(),
          false
        );
        customMsgId = this.addMsgToConversation(newMsg);
        self.scrollToBottom();
        self.directLineObj
          .postActivity({
            channelData: {
              authObj: self.authObj,
              geoLocation: self.geoLocationObj,
              jwtToken: self.jwtToken,
              customUserId: customMsgId,
              username: this._appStateService.username,
              email: this.userEmail,
              employeeId: this.userID,
              contact: this.contact,
            },
            from: { id: self.userName },
            type: "message",
            value: FormData,
          })
          .subscribe(
            (obs) => {
              console.log("form success");
            },
            (error) => {
              console.log(`ERROR: ${JSON.stringify(error)}`);
            }
          );
      }
      // Reset
      this.CurrentAction = { convId: null };
      this.isShowMoreClicked = false;
      this.disableDoneButton(convx); // disable the button
    
    } else {
      this.fieldValidationError = true;
      this.fieldValidationMsg = val.requiredMsg;
    }
    this.searchText ='';
  }

  public AddNegativeCommentsSubmit(textAreaForm: any) {
    console.log(
      "ChatBoxComponent ~ AddCommentsSubmit ~ textAreaValue",
      textAreaForm
    );
    console.log("ChatBoxComponent ~ AddCommentsSubmit ~ textAreaValue",textAreaForm.value )
    let formData=textAreaForm.value ;
    this.postMsgToBot(formData,false);
  }
  public StatusCardActionSet1(action: KbCardNewModel, actionidx: number, convIdx: number) {
    this.fiveticketIndex=null
    console.log("ChatBoxComponent ~ StatusCardActionSet ~ convIdx", convIdx)
    console.log("ChatBoxComponent ~ StatusCardActionSet ~ actionidx", actionidx)
    console.log("ChatBoxComponent ~ StatusCardActionSet ~ action", action)
    this.statusFiveColor=actionidx
    this.isShowMoreClicked = false;
    console.log(
      "ChatBoxComponent ~ StatusCardActionSet ~ action",
      action['data'].msteams.value
    );
    this.postMsgToBot(action['data'].msteams.value, false);
  }

  public StatusCardActionSet(action: CustomStatusCardModel, actionidx: number, convIdx: number) {
    this.fiveticketIndex=null
    console.log("ChatBoxComponent ~ StatusCardActionSet ~ convIdx", convIdx)
    console.log("ChatBoxComponent ~ StatusCardActionSet ~ actionidx", actionidx)
    console.log("ChatBoxComponent ~ StatusCardActionSet ~ action", action)
    this.statusFiveColor=actionidx
    this.isShowMoreClicked = false;
    console.log(
      "ChatBoxComponent ~ StatusCardActionSet ~ action",
      action['data'].msteams.value
    );
    this.postMsgToBot(action['data'].msteams.value, false);

    // action.markOption(actionidx);
    
    // this.currentConvid=convIdx

    // this.currentConvid=convIdx
    // if (this._currConnState == ConnectionStatus.Online) {
    //   let customMsgId: number;
    //   let newMsg: ChatMessageModel = new ChatMessageModel(
    //     MsgAuthor.User,
    //     `${action["title"]}`,
    //     MsgType.TextOnly,
    //     new Date(),
    //     false
    //   );
    //   customMsgId = this.addMsgToConversation(newMsg);
    //   self.scrollToBottom();
    //   self.directLineObj
    //     .postActivity({
    //       channelData: {
    //         authObj: self.authObj,
    //         geoLocation: self.geoLocationObj,
    //         jwtToken: self.jwtToken,
    //         customUserId: customMsgId,
    //         username: this._appStateService.username,
    //         email: this.userEmail,
    //         employeeId: this.userID,
    //         contact: this.contact,
    //       },
    //       from: { id: self.userName },
    //       type: "message",
    //       value: action.data[0],
    //     })
    //     .subscribe(
    //       (obs) => {
    //         console.log("form success");
    //       },
    //       (error) => {
    //         console.log(`ERROR: ${JSON.stringify(error)}`);
    //       }
    //     );
    // }


  }
  public disableDoneButton(convx: number) {
    console.log("ChatBoxComponent ~ disableDoneButton ~ convx", convx);
    this.disableButtonAction.convId = convx;
    this.disableButtonAction.isClicked = true;
  }

  private postLoginSuccess() {
    this.directLineObj
      .postActivity({
        from: { id: self.userName },
        name: "UserLoginSuccess",
        type: "event",
        value: "",
      })
      .subscribe((data) => {
        console.log("SUCCESS: Requested for Greeting dialog!");
      });
  }

  private postLogoutSuccess(msg, callback) {
    this.directLineObj
      .postActivity({
        from: { id: self.userName },
        name: "UserLogoutOnClose",
        type: "event",
        value: "",
      })
      .subscribe((data) => {
        console.log("SUCCESS: Logged out successfully!");
        callback("success");
        //window = self;
        // window.close()
      });
  }

  // private onLogout() {
  //   this.authService.logoutUser().subscribe((data) => {
  //     this.router.navigate(["/login"]);
  //   });
  // }
  // private postUserDetails(details: any) {
  //   this.directLineObj.postActivity({
  //     from: { id: self.userName },
  //     name: 'UserDetailSent',
  //     type: 'event',
  //     value: details
  //   })
  //     .subscribe((data) => {
  //       console.log("SUCCESS: Sent the User Details to backend!");
  //     });
  // }

  private postMsgToBot(userMsg, shouldSendAsValue: boolean = true) {
    // TODO: Re-write the below code to incorporate delivery report in form data sending.
    if (!shouldSendAsValue) {
      // Form submission
      let formPayload = this.createFormPayload(userMsg);
      console.log("postMsgToBot ~ formPayload", formPayload)
      this.subscription.add(
        self.directLineObj
          .postActivity(formPayload)
          .subscribe((data) => console.log("** Form posted: ", data))
      );
      return;
    }

    // First add the user message to the conversation list, and then POST it.
    let customMsgId: number;
    let newMsg: ChatMessageModel = new ChatMessageModel(
      MsgAuthor.User,
      userMsg,
      MsgType.TextOnly,
      new Date(),
      false
    );
    customMsgId = this.addMsgToConversation(newMsg);
    self.scrollToBottom();

    // POST the message.
    self.directLineObj
      .postActivity(this.createUserMsgPayload(userMsg, customMsgId))
      .subscribe(
        (message) => {
          console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);

          // Handle resending
          if (!message.includes("|")) {
            // Show resend icons for all pending messages.
            console.log("Retry sending the message!!");
            this.flagPendingMsgsForRetry();
          }
          return;
        },
        (error) => {
          console.log(`** postMsgToBot ERROR: ${JSON.stringify(error)}`);

          // Acknowledge the message receipt as NOT sent and show retry button.
          // self.postedErrorMsg(usrmsg);
          return;
        }
      );
  }

  flagPendingMsgsForRetry() {
    for (let i in this.ackPendingIdxList) {
      let idx = this.ackPendingIdxList[i];
      this.conversation[idx].flagForResend();
    }
    console.log("** Flagged all pending messages for resend!!");
  }

  resendUserMsg(conversationIdx: number) {
    let resendingMsg: ChatMessageModel = this.conversation.splice(
      conversationIdx,
      1
    )[0];
    console.log("** Resending the message: ", resendingMsg);

    // Also remove the entry from the ledger
    this._conversationCustomIdLedger.splice(conversationIdx, 1)[0];

    // Send the message
    this.postMsgToBot(resendingMsg.msgBody);
  }

  createUserMsgPayload(msg: string, customMsgId: number) {
    return {
      from: { id: self.userName },
      type: "message",
      text: msg,
      channelData: {
        authObj: self.authObj,
        geoLocation: self.geoLocationObj,
        jwtToken: self.jwtToken,
        customUserId: customMsgId,
        username: this._appStateService.username,
        email: self.authObj.email,
        employeeId: this.userID,
        contact: this.contact,
      },
    };
  }

  createFormPayload(formData: any) {
    console.log("ChatBoxComponent ~ createFormPayload ~ formData", formData);
    // let displayText = (formData instanceof String) ? formData : "Form submitted!";
    let displayText: any;
    if (formData.operationType == "backmenu") {
      displayText = "Back To Menu";
    } else if(formData.operationType == "query")
    displayText = "Change Query";
    
    else {
      displayText = typeof formData === "string" ? formData : "Submitted";
    }

    return {
      from: { id: self.userName },
      type: "message",
      text: displayText,
      value: formData,
      channelData: {
        authObj: self.authObj,
        geoLocation: self.geoLocationObj,
        jwtToken: self.jwtToken,
        username: this._appStateService.username,
        email: self.authObj.email,
      },
    };
  }

  submitFormCard(formData) {
    console.log("submitFormCard ~ formData", formData)
    this.postMsgToBot(formData, false);
  }

  submitStatusCard(formData) {
    this.postMsgToBot(formData, false);
  }

  acknowledgeUserMessage(userMsg: any) {
    let conversationIdx: number = userMsg.channelData.customUserId;
    // if (conversationIdx >= this.conversation.length) {
    if (this.ackPendingIdxList.includes(conversationIdx)) {
      console.log("Acknowledgement received!!");
      this.conversation[conversationIdx].acknowledgeMsg();

      // Also remove it from the ackPendingIdxList
      let delIdx = this.ackPendingIdxList.indexOf(conversationIdx);
      this.ackPendingIdxList.splice(delIdx, 1);
    } else {
      console.log(
        "** ATTENTION: Conversation-list is out of sync or message is already acknowledged!!",
        userMsg
      );
    }
  }

  // This controls the modal dialog of the input-container.
  showModalMsg(msgType: number, msgBody: string) {
    this._modalMsgBody = msgBody;
    this._shouldShowModalMsg = true;
    this._modalMsgType = msgType;
    this._isTxtDisabled = msgType == 2;
  }

  hideModalMsg() {
    this._modalMsgBody = null;
    this._shouldShowModalMsg = false;
    this._modalMsgType = -1;
    this._isTxtDisabled = false;
  }

  showHideModalMsg(msgType: number, msgBody: string) {
    this.showModalMsg(msgType, msgBody);
    setTimeout(() => {
      this.hideModalMsg();
    }, 600);
  }

  public toggleBtnVisibility(card: NewTktCardModel) {
    card.isButtonsShown = !card.isButtonsShown;
  }

  public hideCardBtns(card: NewTktCardModel) {
    card.isButtonsShown = false;
  }

  public showCardBtns(event: Event, card: NewTktCardModel) {
    event.stopPropagation();
    card.isButtonsShown = true;
  }

  private addMsgToConversation(newMsg: ChatMessageModel): number {
    let msgCount = this.conversation.length;
    let customId: number;
    let lastMsg: ChatMessageModel;

    if (msgCount > 0) {
      lastMsg = this.conversation[msgCount - 1];
      // Check if last message avatar needs to be hidden.
      if (lastMsg.author == newMsg.author) {
        lastMsg.isAvatarShown = false;
      }
    }

    // Make last message react to the new message.
    if (
      this.reactPendingIdxList.length > 0 &&
      newMsg.author == MsgAuthor.User
    ) {
      for (let i in this.reactPendingIdxList) {
        let convIdx = this.reactPendingIdxList[i];
        console.log(`** ConvIdx: ${convIdx}`);

        if (
          typeof this.conversation[convIdx].msgBody.reactToNewMessage ===
          "function"
        ) {
          this.conversation[convIdx].msgBody.reactToNewMessage(newMsg);
        } else if (this.chkArrEleCanReact(this.conversation[convIdx].msgBody)) {
          for (let msgItem of this.conversation[convIdx].msgBody) {
            msgItem.reactToNewMessage(newMsg);
          }
        }
      }
      this.reactPendingIdxList = [];
    }

    // Handle pending acknowledgement messages.
    if (newMsg.isAckPending) {
      this.ackPendingIdxList.push(this.conversation.length);
    }

    // Add new message to conversation list & generate CustomID.
    customId = this.conversation.push(newMsg) - 1;

    // Add new message to react-pending list
    if (
      typeof newMsg.msgBody.reactToNewMessage === "function" ||
      this.chkArrEleCanReact(newMsg.msgBody)
    ) {
      this.reactPendingIdxList.push(customId);
    }

    // Push CustomID to ledger
    if (newMsg.author == MsgAuthor.User) {
      this._conversationCustomIdLedger.push(customId);
    } else {
      // There is no use of customID for messages from bot.
      if (document.hidden) {
        if (this._appStateService.isAgent) {
          self.notify(newMsg.msgText);
        }
      }
      this._conversationCustomIdLedger.push(-1);
    }

    // Set focus on TextArea
    this.focusOnTA();

    return customId;
  }

  private chkArrEleCanReact(msgBody: any) {
    const chkResult =
      Array.isArray(msgBody) &&
      msgBody.length > 0 &&
      typeof msgBody[0].reactToNewMessage === "function";
    console.log("[chkArrEleCanReact] msgBody: ", msgBody);
    console.log("[chkArrEleCanReact] chkResult: ", chkResult);
    return chkResult;
  }

  private scrollToBottom() {
    clearTimeout(this._scrollTimer);
    this._scrollTimer = setTimeout(function () {
      let div2 = document.getElementsByClassName("bottom-rise-enabler-2")[0];
      if (div2) {
        div2.scrollTop = div2.scrollHeight;
      }
    }, 200);
  }

  private focusOnTA() {
    document.getElementById("textareaMsg").focus();
  }

  // NOTE: rawData is the "attachments" in the original response.
  private parseRawToStatusCards(rawData: any): Array<TktStatusCardModel> {
    // text contains '----'
    let dataList: Array<any> = rawData[0].content.body;
    console.log(
      "ChatBoxComponent ~ parseRawToStatusCards ~ dataList",
      dataList
    );
    let blockLength: number = 5;
    let itCount = (dataList.length - 1) / blockLength;
    console.log("ChatBoxComponent ~ parseRawToStatusCards ~ itCount", itCount);
    let baseIdx: number;
    let newStatusCard: TktStatusCardModel,
      tktNumber: string,
      desc: string,
      assignGroup: string,
      assignTo: string,
      lastUpdate: string,
      status: number;
    let statusCards: Array<TktStatusCardModel> = [];

    for (let i = 0; i < itCount; i++) {
      baseIdx = i * 5;
      console.log(dataList[4].text.split(":")[1], "dataList[baseIdx].text");
      // tktNumber = dataList[baseIdx].text.split(':')[1].trim();
      tktNumber = dataList[baseIdx + 1].text;
      desc = dataList[baseIdx + 2].text.split(":")[1].trim();
      // assignGroup = dataList[1 + 2].text.split(":")[1].trim();
      // assignTo = dataList[1 + 3].text.split(":")[1].trim();
      // if (assignTo == "") {
      //   assignTo = "Yet to be assigned";
      // }
      // lastUpdate = dataList[1 + 4].text;
      status = dataList[baseIdx + 4].text.split(":")[1].trim();

      newStatusCard = new TktStatusCardModel(
        tktNumber,
        desc,
        assignGroup,
        assignTo,
        lastUpdate,
        status
      );
      statusCards.push(newStatusCard);
    }

    return statusCards;
  }

  // to display in group
  // NOTE: rawData is the "attachments" in the original response.
  private parseRawToStatusCardsGroup(rawData: any, msgTime) {
    let newTicketCardGroup = new NewTktCardModel(
      rawData,
      true,
      TicketType.Incident
    );
    /////

    //let newInfoCard = new InfoCardModel(rawData);

    if (self.isCollectingInfoArticles) {
      // Add the new item to last item of conversation
      self.conversation[self.conversation.length - 1].msgBody.push(
        newTicketCardGroup
      );
    } else {
      // Create a new entry in conversation
      let newMsg = new ChatMessageModel(
        MsgAuthor.Bot,
        [newTicketCardGroup],
        MsgType.ItsmIncStatusGroup,
        msgTime,
        false
      );
      self.addMsgToConversation(newMsg);
      self.isCollectingInfoArticles = true; // Reset when next text message is received.
    }
  }

  //Single status card
  private renderSingleStatusCard(rawData: any, cardType: string, msgTime) {
    console.log("ChatBoxComponent ~ renderSingleStatusCard ~ rawData", rawData);
    let newSingleStatusCard = new CustomSingleStatusCardModel(
      rawData,
      msgTime,
      cardType
    );
    //  if (self.isCollectingInfoArticles) {
    //    // Add the new item to last item of conversation
    //    self.conversation[self.conversation.length - 1].msgBody.push(
    //      newSingleStatusCard
    //    );
    //  } else {
    // Create a new entry in conversation
    let newMsg = new ChatMessageModel(
      MsgAuthor.Bot,
      [newSingleStatusCard],
      MsgType.SingleStatusCard,
      msgTime,
      false
    );
    console.log("ChatBoxComponent ~ renderSingleStatusCard ~ newMsg", newMsg);

    self.addMsgToConversation(newMsg);
    self.isCollectingInfoArticles = true; // Reset when next text message is received.
    //  }
  }

  private addInfoCardToConversation(rawData: any, msgTime: Date) {
    // Parse raw into info-card model
    let newInfoCard = new InfoCardModel(rawData);

    if (self.isCollectingInfoArticles) {
      // Add the new item to last item of conversation
      self.conversation[self.conversation.length - 1].msgBody.push(newInfoCard);
    } else {
      // Create a new entry in conversation
      let newMsg = new ChatMessageModel(
        MsgAuthor.Bot,
        [newInfoCard],
        MsgType.InfoCardCollection,
        msgTime,
        false
      );
      self.addMsgToConversation(newMsg);
      self.isCollectingInfoArticles = true; // Reset when next text message is received.
    }
  }

  private refreshToken() {
    console.log("** Refreshing DirectLine token!");
    self._directLine.refreshDirectLineToken(self.directLineToken);
  }

  private toggleCardGroupState(conversationIdx: number) {
    this.conversation[conversationIdx].isExpanded =
      !this.conversation[conversationIdx].isExpanded;
  }

  // Handle Calendar
  date: Date = new Date();

  settings = {
    bigBanner: false,
    timePicker: false,
    format: "dd-MM-yyyy",
    defaultOpen: true,
    // closeOnSelect: true
  };

  public onDateSelect(dateInput: any) {
    if (self.sendCalDate) {
      self.directLineObj
        .postActivity({
          from: { id: self.userName },
          type: "message",
          text: new String(self.date).slice(0, 15),
          channelData: {
            authObj: self.authObj,
            geoLocation: self.geoLocationObj,
            jwtToken: self.jwtToken,
          },
        })
        .subscribe(
          function (message: any) {
            console.log(`** postMsgToBot Success: ${JSON.stringify(message)}`);
            return;
          },
          function (error: any) {
            console.log(`** Error in posting: ${JSON.stringify(error)}`);
            return;
          }
        );
      self.sendCalDate = false;
    } else {
      self.date = new Date();
    }
  }

  addNote() {
    self.msgToBot = "Add Note";
    self.sendMsgToBot();
  }

  public openIframe(url: string) {
    this._appStateService.urlIframe = url;
    this._appStateService.isIframeShown = true;
  }

  public openPopup(url: string) {
    window.open(url, "popup", "width=900,height=600");
    return false;
  }

  //Toggle Listen Mode
  toggleListenMode() {
    !this.isListening ? this.listenUserSpeech() : this.stopListenUserSpeech();
  }

  textToSpeechClient(msg: any) {
    let wordBoundaryList = [];
    let speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      this.voiceSubscriptionDetails["subscriptionKey"],
      this.voiceSubscriptionDetails["serviceRegion"]
    );
    let player = new SpeechSDK.SpeakerAudioDestination();

    player.onAudioEnd = function (_) {
      console.log("playback finished");
      wordBoundaryList = [];
    };

    var audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);

    speechConfig.speechRecognitionLanguage =
      this.voiceSubscriptionDetails["speechRecognitionLanguage"];

    let synthesizer = new SpeechSDK.SpeechSynthesizer(
      speechConfig,
      audioConfig
    );

    // The event synthesizing signals that a synthesized audio chunk is received.
    // You will receive one or more synthesizing events as a speech phrase is synthesized.
    // You can use this callback to streaming receive the synthesized audio.
    synthesizer.synthesizing = function (s, e) {
      console.log(e);
      console.log(
        "(synthesizing) Reason: " +
          SpeechSDK.ResultReason[e.result.reason] +
          "Audio chunk length: " +
          e.result.audioData.byteLength
      );
    };

    // The synthesis started event signals that the synthesis is started.
    synthesizer.synthesisStarted = function (s, e) {
      console.log(e);
      console.log("(synthesis started)");
    };

    // The event synthesis completed signals that the synthesis is completed.
    synthesizer.synthesisCompleted = function (s, e) {
      console.log(e);
      console.log(
        "(synthesized)  Reason: " +
          SpeechSDK.ResultReason[e.result.reason] +
          " Audio length: " +
          e.result.audioData.byteLength
      );
      this.textToSpeechmsg = "";
    };

    // The event signals that the service has stopped processing speech.
    // This can happen when an error is encountered.
    synthesizer.SynthesisCanceled = function (s, e) {
      const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(
        e.result
      );
      let str =
        "(cancel) Reason: " +
        SpeechSDK.CancellationReason[cancellationDetails.reason];
      if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
        str += ": " + e.result.errorDetails;
      }
      console.log("reason: " + e.result.reason);
    };

    // This event signals that word boundary is received. This indicates the audio boundary of each word.
    // The unit of e.audioOffset is tick (1 tick = 100 nanoseconds), divide by 10,000 to convert to milliseconds.
    synthesizer.wordBoundary = function (s, e) {
      window.console.log(e);
      console.log(
        "(WordBoundary), Text: " +
          e.text +
          ", Audio offset: " +
          e.audioOffset / 10000 +
          "ms."
      );
      wordBoundaryList.push(e);
    };

    const complete_cb = function (result) {
      if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
        console.log("synthesis finished");
      } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
        console.log("synthesis failed. Error detail: " + result.errorDetails);
      }
      console.log("complete_cb: " + result);
      synthesizer.close();
      synthesizer = undefined;
    };

    const err_cb = function (err) {
      console.log(err);
      synthesizer.close();
      synthesizer = undefined;
    };

    synthesizer.speakTextAsync(msg, complete_cb, err_cb);
  }

  listenUserSpeech() {
    let previousTextContent = self.msgToBot ? self.msgToBot : "";
    let statusDiv = "";

    let audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    let speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      this.voiceSubscriptionDetails["subscriptionKey"],
      this.voiceSubscriptionDetails["serviceRegion"]
    );

    speechConfig.speechRecognitionLanguage =
      this.voiceSubscriptionDetails["speechRecognitionLanguage"];
    speechConfig.endpointId = this.voiceSubscriptionDetails["endpointId"];

    this.isListening = true;
    this.restartMicTimer();
    console.log("listening");

    self.reco = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
    // console.log(JSON.stringify(this.reco));

    //  The event recognizing signals that an intermediate recognition result is received.
    self.reco.recognizing = (s, e) => {
      this.restartMicTimer();
      console.log("recognizing text", e.result.text);
      this.msgToBot = previousTextContent + " " + e.result.text;
    };

    //  The event recognized signals that a final recognition result is received.
    self.reco.recognized = (s, e) => {
      this.restartMicTimer();
      console.log("recognized text", e.result.text);

      // Indicates that recognizable speech was not detected, and that recognition is done.
      if (e.result.reason === SpeechSDK.ResultReason.NoMatch) {
        var noMatchDetail = SpeechSDK.NoMatchDetails.fromResult(e.result);
        statusDiv +=
          "(recognized)  Reason: " +
          SpeechSDK.ResultReason[e.result.reason] +
          " NoMatchReason: " +
          SpeechSDK.NoMatchReason[noMatchDetail.reason] +
          "\r\n";
        console.log(statusDiv);
      } else {
        statusDiv +=
          "(recognized)  Reason: " +
          SpeechSDK.ResultReason[e.result.reason] +
          " Text: " +
          e.result.text +
          "\r\n";
        console.log(statusDiv);
        //previousTextContent += ' ' + e.result.text;
        this.msgToBot = e.result.text;
        this.sendMsgToBot();
        //this.textToSpeechClient(this.msgToBot);
      }
    };

    self.reco.canceled = function (s, e) {
      window.console.log(e);

      statusDiv += "(cancel) Reason: " + SpeechSDK.CancellationReason[e.reason];
      if (e.reason === SpeechSDK.CancellationReason.Error) {
        statusDiv += ": " + e.errorDetails;
        console.log(statusDiv);
      }
      statusDiv += "\r\n";
    };

    // Signals that a new session has started with the speech service
    self.reco.sessionStarted = function (s, e) {
      window.console.log(e);
      statusDiv += "(sessionStarted) SessionId: " + e.sessionId + "\r\n";
      console.log(statusDiv);
    };

    // Signals the end of a session with the speech service.
    self.reco.sessionStopped = function (s, e) {
      window.console.log(e);
      statusDiv += "(sessionStopped) SessionId: " + e.sessionId + "\r\n";
      this.isListening = false;
    };

    // Signals that the speech service has started to detect speech.
    self.reco.speechStartDetected = function (s, e) {
      window.console.log(e);
      statusDiv += "(speechStartDetected) SessionId: " + e.sessionId + "\r\n";
      console.log(statusDiv);
    };

    // Signals that the speech service has detected that speech has stopped.
    self.reco.speechEndDetected = function (s, e) {
      window.console.log(e);
      statusDiv += "(speechEndDetected) SessionId: " + e.sessionId + "\r\n";
      console.log(statusDiv);
    };

    // Starts recognition
    self.reco.startContinuousRecognitionAsync();
  }

  stopListenUserSpeech() {
    this.isListening = false;
    if (self.reco) {
      self.reco.stopContinuousRecognitionAsync(
        function () {
          self.reco.close();
          self.reco = undefined;
        },
        function (err) {
          self.reco.close();
          self.reco = undefined;
        }
      );
    }
  }

  restartMicTimer() {
    clearTimeout(this._micTimer);
    this._micTimer = setTimeout(() => {
      this.stopListenUserSpeech();
    }, 4000);
  }

  notify(msg: string) {
    //our function to be called on click
    let options = {
      //set options
      body: msg,
      // icon: "assets/images/ironman.png" //adding an icon
    };
    this._pushNotifications
      .create("You received a new message", options)
      .subscribe(
        //creates a notification
        (res) => console.log(res),
        (err) => console.log(err)
      );
  }

  public sendStatusCardMsg(tktObj: TktStatusCardModel) {
    const msg = `Yes, show status of ${tktObj.tktNumber}`;
    this.postMsgToBot(msg);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _directLine: DirectLineService,
    private _sharedService: SharedService,
    private authService: AuthenticationService,
    public _appStateService: AppStateService,
    private _i18nService: I18nService,
    private httpClient: HttpClient,
    private _pushNotifications: PushNotificationsService
  ) {
    this._pushNotifications.requestPermission();
    this.i18nTexts = this._i18nService.getLocaleTextsForComponent(
      this.constructor.name
    );
    // this._appStateService.currentMessage.subscribe(message => {
    //   console.log(message);
    //   //this.execFun()
    //   this.postLogoutSuccess();
    // });
    this.isFileUploaded = false;
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHander($event: any) {
    //let c = confirm("Are you sure?")
    if (true) {
      var promise = new Promise((resolve, reject) => {
        this.directLineObj
          .postActivity({
            // from: { id: self.digitalID },
            from: { id: self.userName },
            name: "UserLogoutOnClose",
            type: "event",
            value: "window closed",
          })
          .subscribe((data) => {
            console.log("logout success");
            resolve(data);
          });
      });
      promise
        .then((data) => {
          console.log("in first then");
          console.log(data);
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              console.log("timeout is finished");
              resolve("got the first response, now wait");
            }, 1000);
          });
        })
        .then((data) => {
          console.log("in second then");
          console.log(data);
          // window.open('', '_self', '')
          // window.close()
          $event.returnValue = "Your data will be lost";
        });
    }
  }

  ngOnInit() {
    self = this;
    let sub = this.route.queryParams.subscribe((params) => {
      self.fetchParamsData(params);
    });

    // TODO: Pass the conversation_id alongwith the secret, to restore the interrupted conversation.
    // TODO: For token, pass streamUrl
    this.directLineObj = this._directLine.createDirectlineConnection(
      self.directLineToken
    );
    this._appStateService.dlObj = this.directLineObj;
    this._sharedService.userClickedfromLeftmenu = this.clickedfromLeftMenu;
    this.checkDirectlineConnection();
    this.subscribeMessages();
    if (this._sharedService.isLoggedOut == undefined) {
      this._sharedService.isLoggedOut =
        this._sharedService.trigerLogoutEvent.subscribe((msg: string) => {
          // this.postLogoutSuccess("success", this.onLogout);
        });
    }
    console.log("Voice: ", this._appStateService.getVoiceSubscriptionDetails());
    this.voiceSubscriptionDetails =
      this._appStateService.getVoiceSubscriptionDetails();
    // console.log("User: ", this._appStateService.getUserDetails());
    if (this._appStateService.getUserDetails()) {
      this.userEmail = this._appStateService.getUserDetails().email;
      this.userID = this._appStateService.getUserDetails().userID;
      this.contact = this._appStateService.getUserDetails().contact;
      this.geoLocationObj = this._appStateService.getUserDetails().location;
    } else {
      this.userEmail = localStorage.getItem("email");
      this.userID = localStorage.getItem("employeeId");
      this.contact = localStorage.getItem("contact");
      this.geoLocationObj = {
        city: localStorage.getItem("city"),
        state: localStorage.getItem("state"),
      };
    }

    // Post login success
    if (this._appStateService.shouldShowGreeting) {
      this.postLoginSuccess();
      // this.postUserDetails(this.userDetail);
      this._appStateService.shouldShowGreeting = false;
    }

    // let customMsgId: number
    // let newMsg: ChatMessageModel = new ChatMessageModel(MsgAuthor.Bot, "Hi Ashmeet, this is *|b_ IT Virtual Assistant _b|*, How may I help you today?", MsgType.TextOnly, new Date());
    // customMsgId = this.addMsgToConversation(newMsg);
    self.scrollToBottom();

    // this.hideModalMsg();
    this.focusOnTA();

    // Set timer to refresh token after every 25mins.
    this._timerTokenRefresh = setInterval(() => {
      self.refreshToken();
    }, 1500000);
  }

  ngOnDestroy() {
    // this.postLogoutSuccess();
    window.clearInterval(this._timerTokenRefresh);
    this.subscription.unsubscribe();
  }
}
